import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/pientere_tuinen_logo_notext.png';
import probe from '../../../images/mobile_probe.png';
import { PTRegisterRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';

function RegisterCard() {
  return (
    <div className="gcscard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left mt-2 text-2xl font-bold text-gray-900">
            Registreer je bodemsensor
          </h4>
          <img src={logo} className="inline-right h-[70px]" alt="Pientere Tuinen Logo" />
        </div>

        <div className="h-full p-5 mb-5">
          <p className="font-normal text-gray-700 dark:text-gray-400">
            <img src={probe} alt="sensor" className="ml-auto" width="100" />
            Jouw bodemsensor is nog niet geactiveerd. Als je de sensor hebt ontvangen,
            kun je hem hier registreren. Meer informatie vind je in de bijgeleverde
            handleiding. Registreer je sensor voordat je hem in de tuin plaatst.
          </p>
        </div>

        <Link
          to={PTRegisterRoute()}
          className="btn-card border group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Registreer je sensor
            <ArrowRight />
          </span>
        </Link>

      </div>
    </div>
  );
}

export default RegisterCard;
