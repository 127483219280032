import React from 'react';
import LiveCard from './LiveCard';
import RegisterCard from './RegisterCard';
import useLatestPientereTuinenAsset from '../../hooks/useLatestPientereTuinenAsset';
import Loader from '../../Loader';
import ErrorCard from '../../ErrorCard';

function StatusCard() {
  const { asset, loading: assetLoading, error } = useLatestPientereTuinenAsset();

  if (assetLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorCard moduleName="Pientere Tuinen" />;
  }

  return (asset) ? (<LiveCard />) : (<RegisterCard />);
}

export default StatusCard;
