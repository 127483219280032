import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import dashboard1 from '../images/dashboard1.jpg';
import dashboard2 from '../images/dashboard2.jpg';
import dashboard3 from '../images/dashboard3.jpg';
import useMyRoles from './hooks/useMyRoles';
import Loader from './Loader';
import AdminCard from './modules/admin/AdminCard';
import PientereTuinenCardSupport from './modules/pientere-tuinen/PientereTuinenCardSupport';
import PlatformAlerts from '../PlatformAlerts';
import { RoleUser } from '../generated/gql/types';
import MultipleSensorStatusCard from './modules/pientere-tuinen/MultipleSensorStatusCard';
import StatusCard from './modules/pientere-tuinen/StatusCard';

function Dashboard() {
  const myRoles = useMyRoles();
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(localStorage.getItem('agreement_v1') != null);

  useEffect(() => {
    if (agreementAccepted) {
      localStorage.setItem('agreement_v1', '1');
    }
  }, [agreementAccepted]);

  if (!myRoles) {
    return <Loader />;
  }

  function renderPTCards() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return (
        <>
          <MultipleSensorStatusCard />
          <PientereTuinenCardSupport />
        </>
      );
    }

    return (
      <>
        <MultipleSensorStatusCard />
        <StatusCard />
        <PientereTuinenCardSupport />
      </>
    );
  }

  return (
    <div className="mx-2.5 md:mx-0">
      {!agreementAccepted ? (
        <div className="flex items-center justify-center">
          <div className="h-full w-full p-4 md:h-auto max-w-2xl">
            <p className="pt-6 pb-2 text-lg font-bold">De gebruikersovereenkomst voor Pientere Tuinen is aangepast.</p>

            De nieuwe versie kun je zelf
            <a
              className="underline whitespace-nowrap ml-1"
              target="_blank"
              href="https://workdrive.goodcitysense.nl/external/8099dbbb5e1a270da9766d789bdf05f04afede9050a48dbc43ddb75d6a1b53ea"
              rel="noreferrer"
            >
              downloaden
            </a>
            .

            De aanpassingen zijn bedoeld om betere support te kunnen bieden.
            Bijvoorbeeld als het gaat om het retourneren/opsturen van het Pientere Tuin pakket of het organiseren van
            bijeenkomsten.
            De aanpassing is ook bedoeld om beter onderzoek te kunnen doen door de data en locatie van de sensor te
            koppelen.
            Tot slot is een bepaling opgenomen om de gebruikersvoorwaarden in de toekomst (als dat nodig is) te kunnen
            aanpassen.
            Die aanpassingen zullen we altijd vooraf communiceren.

            <p className="py-2">
              Klik hieronder om de nieuwe gebruikersovereenkomst te accepteren.
            </p>
            <button
              type="button"
              color="gray"
              onClick={() => setAgreementAccepted(true)}
              className="w-64 border bg-wc-lightgray border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 mb-5 text-center font-medium z-50 rounded-lg"
            >
              <span className="flex items-center rounded-md text-sm px-4 py-2">Accepteer & Ga verder</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <section className="bg-white">
            <PlatformAlerts />
            <div className="grid justify-center py-6 px-4">
              <h2 className="mb-4 text-center text-4xl tracking-tight font-extrabold text-gray-900">
                Welkom op het Citizense Platform
              </h2>
              <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">
                De plek waar data voor een gezonde leefomgeving bij elkaar komt
              </p>
            </div>
            <div className="grid gap-4 grid-cols-3 dbimgcont mb-6">
              <img className="w-full rounded-lg dbimg " src={dashboard1} alt="illustratie straat" />
              <img className="w-full rounded-lg dbimg " src={dashboard2} alt="illustratie bouw" />
              <img className="w-full rounded-lg dbimg " src={dashboard3} alt="illustratie tuin" />
            </div>
          </section>

          <section className="flex flex-row flex-wrap gap-4 ">
            {myRoles.userRoles.find((r) => r === RoleUser.UserPientereTuinenUser) && (
              <>
                { renderPTCards() }
              </>
            )}

            {myRoles.userRoles.find((r) => r === RoleUser.UserGlobalAdmin) && (
            <AdminCard />
            )}
          </section>
          <Footer />
        </>
      )}

    </div>
  );
}

export default Dashboard;
