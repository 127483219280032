import { TFunction } from 'i18next';
import { LatLng } from 'leaflet';
import { min } from 'lodash';
import { Asset, Property } from './generated/gql/types';
import { DropdownOption } from './components/modules/pientere-tuinen/Dropdown';

export enum PientereTuinenAssetProperty {
  GardenSize = 'garden_size',
  GardenOrientation = 'garden_orientation',
  GardenHardening = 'garden_hardening',
  SoilType = 'soil',
  Coordinates = 'location',
  SensorId = 'sensor_id',
  OrganizationId = 'organization_id',
  UserId = 'user',
  NotificationsEnabled = 'notifications_enabled',
}

export function getProperty(
  asset: Asset,
  property: PientereTuinenAssetProperty,
): Property | undefined {
  return asset.properties?.find((p) => p.key === property);
}

export function translateProperty(
  asset: Asset,
  property: PientereTuinenAssetProperty,
  t: TFunction,
): string {
  return t((
    asset.properties?.find((p) => p.key === property)?.value ?? 'unknown'
  ).replaceAll('_', ' '));
}

export function parseBoolean(value: String | undefined | null): boolean {
  if (value === undefined || value === null) {
    return false;
  }

  switch (value) {
    case 'true':
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export function validationStringToOptionList(validationString: string, t?: TFunction): DropdownOption[] {
  return validationString.replace('in:', '').split(',').map((item) => (
    {
      name: t ? t(item.replaceAll('_', ' ')) : item.replaceAll('_', ' '),
      value: item,
    }
  ));
}

export function latLongFromProperty(property: Property | undefined): LatLng | undefined {
  if (property !== undefined && property.value) {
    const ll = property.value.split(',');

    if (ll[0] && !Number.isNaN(Number(ll[0])) && ll[1] && !Number.isNaN(Number(ll[1]))) {
      return new LatLng(Number(ll[0]), Number(ll[1]));
    }
  }

  return undefined;
}

export enum Direction {
  ASC,
  DESC,
}
export function generateSearchString(perPage: number, page: number, query: string | undefined, sort: Sort | undefined, filters: Filter[] = []): string {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append('query', query);
  }

  if (sort) {
    searchParams.append('sort', sort.direction === Direction.DESC ? `-${sort.name}` : sort.name);
  }

  const groupedFilters = new Map();
  filters.forEach((filter) => {
    if (!groupedFilters.has(filter.key)) {
      groupedFilters.set(filter.key, [filter.value]);
    } else {
      groupedFilters.set(filter.key, [...groupedFilters.get(filter.key), filter.value]);
    }
  });
  groupedFilters.forEach((value, key) => {
    searchParams.append(`filter[${key}]`, value.join(','));
  });

  searchParams.append('per_page', (min([perPage ?? 24, 100]) ?? 24).toString());
  searchParams.append('page', (page > 0 ? page : 1).toString());

  return searchParams.toString();
}

export function generateFilterString(filters: Filter[], perPage: number, page: number): string {
  const searchParams = new URLSearchParams();

  const groupedFilters = new Map();
  filters.forEach((filter) => {
    if (!groupedFilters.has(filter.key)) {
      groupedFilters.set(filter.key, [filter.value]);
    } else {
      groupedFilters.set(filter.key, [...groupedFilters.get(filter.key), filter.value]);
    }
  });

  groupedFilters.forEach((value, key) => {
    searchParams.append(`filter[${key}]`, value.join(','));
  });

  searchParams.append('per_page', (min([perPage ?? 24, 100]) ?? 24).toString());
  searchParams.append('page', (page > 0 ? page : 1).toString());

  return searchParams.toString();
}

export type Filter = {
  key: string
  value: string
};

export type Sort = {
  name: string
  direction: Direction
};
