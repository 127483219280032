import React, { CSSProperties, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tree } from '../../../generated/gql/types';

function TreeIcon({ tree, style }: TreeIconProps) {
  if (tree.species?.cropType === 'loofhout') {
    return (
      <FontAwesomeIcon
        icon={icon({ name: 'tree-deciduous', family: 'classic', style: 'duotone' })}
        size="4x"
        style={style}
      />
    );
  }
  return (
    <FontAwesomeIcon
      icon={icon({ name: 'tree', family: 'classic', style: 'duotone' })}
      size="4x"
      style={style}
    />
  );
}

export type DropdownOption = {
  name: ReactNode
  value: string
};

type TreeIconProps = {
  tree: Tree
  style: CSSProperties
};

export default TreeIcon;
