import React from 'react';
import { Navbar } from 'flowbite-react';
import Loader from './Loader';
import { DashboardRoute } from './Routes';
import { ReactComponent as GCSLogo } from '../images/good_city_sense_logo.svg';

function LoadingPage() {
  return (
    <div className="container mx-auto">
      <Navbar
        fluid
        rounded
        className="mt-2 pb-2 mb-2 border-b border-slate-500"
      >
        <Navbar.Brand href={DashboardRoute()}>
          <div className="h-18">
            <GCSLogo />
          </div>
        </Navbar.Brand>

      </Navbar>

      <div role="status">
        <Loader />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingPage;
