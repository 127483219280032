import React from 'react';
import { useParams } from 'react-router-dom';
import PientereTuinenFooter from './PientereTuinenFooter';
import logo from '../../../images/pientere_tuinen_logo.png';
import { getProperty, latLongFromProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import Loader from '../../Loader';
import TreeMap from './TreeMap';
import usePientereTuinenAssetBySerial from '../../hooks/usePientereTuinenAssetBySerial';

function TreeMonitorApp() {
  const params = useParams();
  const { asset, loading: assetLoading } = usePientereTuinenAssetBySerial({ serial: params.serial ?? '' });

  if (assetLoading || !asset) {
    return <Loader />;
  }

  const assetCoordinates = latLongFromProperty(
    getProperty(asset, PientereTuinenAssetProperty.Coordinates),
  );

  return (
    <div className="mx-2.5 md:mx-0">
      <section className="bg-white">
        <div className="align-top mb-4 flex items-center">
          <div>
            <img src={logo} className="h-[65px] mr-4 border border-gray-300" alt="Pientere Tuinen Logo" />
          </div>
          <h2 className="inline-block text-4xl tracking-tight font-extrabold text-gray-900 ">
            Bomen rondom jouw sensor
          </h2>
        </div>
      </section>

      <section>
        { assetCoordinates ? (
          <TreeMap assetCoordinates={assetCoordinates} />
        ) : (
          <Loader />
        )}
      </section>
      <PientereTuinenFooter />
    </div>
  );
}

export default TreeMonitorApp;
