const usePientereTuinenSoilTypes = (): SoilType[] => [
  {
    id: 54,
    name: 'Lichte klei',
    key: 'loam_2',
    default_norm_min: 23,
    default_norm_max: 38,
    wilting_point: 16,
  },
  {
    id: 100,
    name: 'Potgrond',
    key: 'sandy_loam_6',
    default_norm_min: 34,
    default_norm_max: 49,
    wilting_point: 26,
  },
  {
    id: 22,
    name: 'Tuinaarde',
    key: 'sand_5',
    default_norm_min: 13,
    default_norm_max: 31,
    wilting_point: 8,
  },
  {
    id: 63,
    name: 'Zavel',
    key: 'sandy_loam_3',
    default_norm_min: 16,
    default_norm_max: 33,
    wilting_point: 10,
  },
  {
    id: 66,
    name: 'Zand',
    key: 'sand_1_1',
    default_norm_min: 4,
    default_norm_max: 10,
    wilting_point: 2,
  },
];

export const defaultSoilType = {
  id: 22,
  name: 'Tuinaarde',
  key: 'sand_5',
  default_norm_min: 13,
  default_norm_max: 31,
  wilting_point: 8,
} as SoilType;

export function findSoilTypeByKey(key: string | undefined): SoilType {
  if (!key) {
    return defaultSoilType;
  }

  return usePientereTuinenSoilTypes().find((item) => item.key === key) ?? defaultSoilType;
}

export type SoilType = {
  id: number,
  name: string,
  key: string,
  default_norm_min: number,
  default_norm_max: number,
  wilting_point: number
};

export default usePientereTuinenSoilTypes;
