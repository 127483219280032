import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  AssetManagementRoute, CreateSensorRegistrationTokensRoute,
  ImportZohoUsersRoute,
  OrganizationManagementRoute, SendVerificationEmailRoute,
  UserManagementRoute,
} from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';

function AdminCard() {
  const userIconStyle = {
    '--fa-primary-color': '#12678F',
    '--fa-secondary-color': '#A6C5D4',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;
  return (
    <div className="gcscard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left mt-2 text-2xl font-bold text-gray-900">
            Admin
          </h4>
          <FontAwesomeIcon
            className="h-[50px] self-center inline-right"
            icon={icon({ name: 'user-gear', family: 'classic', style: 'duotone' })}
            style={userIconStyle}
          />
        </div>

        <div className="h-full p-5 mb-5">
          <div className="rounded-lg bg-white p-5 border border-gray-300">
            <div className="text-gray-900 text-center">
              Manage users & organizations
            </div>
          </div>
        </div>

        <Link
          to={OrganizationManagementRoute()}
          className="btn-card border group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Organization Management
            <ArrowRight />
          </span>
        </Link>

        <Link
          to={UserManagementRoute()}
          className="btn-card border group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            User Management
            <ArrowRight />
          </span>
        </Link>

        <Link
          to={AssetManagementRoute()}
          className="btn-card border group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Asset Management
            <ArrowRight />
          </span>
        </Link>

        <Link
          to={ImportZohoUsersRoute()}
          className="btn-card group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md font-bold text-sm px-4 py-2">
            Import zoho users by organization
            <ArrowRight />
          </span>
        </Link>

        <Link
          to={SendVerificationEmailRoute()}
          className="btn-card group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Sent verification emails
            <ArrowRight />
          </span>
        </Link>

        <Link
          to={CreateSensorRegistrationTokensRoute()}
          className="btn-card group flex h-min
      items-center justify-center p-0.5 m-2 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md font-bold text-sm px-4 py-2">
            Create sensor registration tokens
            <ArrowRight />
          </span>
        </Link>
      </div>
    </div>

  );
}

export default AdminCard;
