import React, { useRef } from 'react';
import {
  Alert,
  Button, TextInput,
} from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  UpdateUserCommand, useDeleteUserWithoutSensorMutation,
  useGetUserDetailsQuery,
  useSendEmailVerificationForUserMutation,
  useUpdateUserMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function UserEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deleteReasonInputRef = useRef<HTMLInputElement>(null);
  const notifyDelete = () => toast.success('Gebruiker is verwijderd.', { autoClose: 7000 });
  const notifyError = () => toast.error('Gebruiker kon niet worden verwijderd.', { autoClose: 7000 });

  const { data: userData, loading: userDataLoading, error: userDataError } = useGetUserDetailsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        id: params.userId ?? '',
      },
    },
  );

  const [sendEmail, {
    data: sendEmailResponse,
    loading: sendEmailLoading,
    error: sendEmailError,
  }] = useSendEmailVerificationForUserMutation();

  const [updateUser, {
    data: updateUserResponse,
    loading: updateUserLoading,
    error: updateUserError,
  }] = useUpdateUserMutation();

  const [deleteUser, {
    loading: deleteUserLoading,
    error: deleteUserError,
  }] = useDeleteUserWithoutSensorMutation();

  const {
    register,
    handleSubmit,
  } = useForm<UpdateUserCommand>({});

  const onSubmit = handleSubmit((updateCommand) => {
    updateUser({
      variables: {
        command: updateCommand,
      },
    });
  });

  function sendEmailForUser() {
    if (userData?.user?.id) {
      sendEmail({
        variables: {
          userId: userData?.user?.id,
        },
      });
    }
  }

  function deleteUserWithReason() {
    if (userData?.user?.id && deleteReasonInputRef.current) {
      deleteUser({
        variables: {
          command: {
            id: userData?.user?.id,
            reason: deleteReasonInputRef.current.value,
          },
        },
      }).then((_) => {
        notifyDelete();
        navigate(-1);
      }).catch((_) => {
        notifyError();
        navigate(-1);
      });
    }
  }

  function handleResendConfirmation() {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to resend the verification email')}`)) {
      sendEmailForUser();
    }
  }

  function handleUserDeleteConfirmation() {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete this user? This cannot be undone!')}`)) {
      deleteUserWithReason();
    }
  }

  if (userDataError) {
    return <div>{userDataError.message}</div>;
  }

  if (!userData?.user || userDataLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="flex flex-col mx-auto max-w-4xl justify-center">
      <form className="flex flex-col gap-y-2 justify-center" onSubmit={onSubmit}>
        <input type="hidden" {...register('id', { value: userData?.user.id })} />
        <div className="text-xl flex gap-x-1">
          <div>Editing user</div>
          <div className="capitalize-first font-bold">
            {userData.user.firstname}
          </div>
          <div className="capitalize-first font-bold">
            {userData.user.lastname}
          </div>
          <div className="font-bold">
            (
            {userData.user.email}
            )
          </div>
        </div>
        <Button type="button" color="info" className="btn p-0" onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={icon({ name: 'arrow-left', family: 'classic', style: 'regular' })}
            size="xl"
          />
        </Button>

        <div className="flex items-center gap-x-2">
          <div className="text-sm text-gray-500 dark:text-gray-400">
            User created on
            {' '}
            {format(parseISO(userData?.user.createdAt), "dd-MM-y 'at' HH:mm", { locale: nl })}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="text-sm text-gray-500 dark:text-gray-400">
            User updated on
            {' '}
            {format(parseISO(userData?.user.updatedAt), "dd-MM-y 'at' HH:mm", { locale: nl })}
          </div>
        </div>
        <div className="flex-col grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextInput
            id="firstname"
            placeholder=""
            type="text"
            disabled
            required
            {...register('firstname', { value: userData?.user.firstname ?? '' })}
          />
          <TextInput
            id="lastname"
            placeholder=""
            type="text"
            disabled
            required
            {...register('lastname', { value: userData?.user.lastname ?? '' })}
          />
        </div>
        <div className="flex gap-x-2 w-full items-center text-sm text-gray-500 dark:text-gray-400">
          <TextInput
            id="email"
            placeholder=""
            required
            type="text"
            className="w-full"
            {...register('email', { value: userData?.user.email })}
          />
          {' '}
          {userData?.user.isEmailVerified ? (<FontAwesomeIcon icon={faCircleCheck} color="green" />) : ('')}
        </div>

        <div className="flex-col space-y-2">
          {sendEmailError && (
          <Alert
            color="failure"
          >
            <span>
              <span className="font-medium">
                Email send failed!
              </span>
              {' '}
              {sendEmailError?.message}
            </span>
          </Alert>
          )}

          {updateUserError && (
          <Alert
            color="failure"
          >
            <span>
              <span className="font-medium">
                Updating user failed!
              </span>
              {' '}
              {updateUserError?.message}
            </span>
          </Alert>
          )}

          {updateUserResponse && (
          <Alert
            color="success"
          >
            <span>
              <span className="font-medium">
                Updating user succeeded,
              </span>
              {' '}
              changed email to
              {' '}
              <span className="font-bold">{updateUserResponse.users.updateUser.email}</span>
            </span>
          </Alert>
          )}

          {sendEmailResponse?.users.sendEmailVerification && (
          <Alert
            color="success"
          >
            <span>
              <span className="font-medium">
                Send email verification successfully
              </span>
            </span>
          </Alert>
          )}

          <div className="flex-col space-y-2">
            {sendEmailLoading || !userData?.user?.id || sendEmailError ? (
              <Button type="button" color="warning" className="p-0" disabled>
                Resend verification email
              </Button>
            ) : (
              <Button type="button" color="warning" className="p-0" onClick={() => handleResendConfirmation()}>
                Resend verification email
              </Button>
            )}

            {updateUserLoading || updateUserError ? (
              <Button type="submit" color="info" className="btn p-0" disabled>
                Save changes
              </Button>
            ) : (
              <Button type="submit" color="info" className="btn p-0">
                Save changes
              </Button>
            )}
          </div>
        </div>
      </form>

      <div className="flex flex-col">
        <h2 className="text-xl font-bold pt-6">Deleting a user is a permanent en not reversible action!</h2>
        {deleteUserLoading || !userData?.user?.id || deleteUserError ? (
          <div className="flex flex-col space-y-2 pt-4">
            <TextInput
              className="left-0 flex-grow border border-none"
              placeholder="Delete reason"
              disabled
            />
            <Button type="button" color="failure" className="p-0" disabled>
              Delete user
            </Button>
          </div>
        ) : (
          <div className="flex flex-col space-y-2 pt-4">
            <TextInput
              className="left-0 flex-grow border border-none"
              placeholder="Delete reason"
              ref={deleteReasonInputRef}
              required
            />
            <Button type="button" color="failure" className="p-0" onClick={() => handleUserDeleteConfirmation()}>
              Delete user
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserEdit;
