import React from 'react';
import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import 'swagger-ui-react/swagger-ui.css';

import { Link } from 'react-router-dom';
import {
  Api,
  useCreateApiSubscriptionKeyMutation,
  useCreateApiSubscriptionMutation,
  useDeleteApiSubscriptionKeyMutation,
  useDeleteApiSubscriptionMutation,
  useGetApisQuery,
  useGetApiSubscriptionsQuery,
} from '../../../generated/gql/types';
import { PTOpenApiSpecRoute } from '../../Routes';
import CopyToClipboardButton from '../admin/CopyToClipboardButton';
import ApiCatalogMeteringStatistics from './ApiCatalogMeteringStatistics';

function PientereTuinenSubscriptionApp() {
  const ptApiName = 'MIJN_PIENTERE_TUIN';
  const ptPlan = 'basic';
  const { t } = useTranslation();

  const { data: apisData } = useGetApisQuery();
  const {
    data: subscriptionsData,
    refetch: refetchSubscriptions,
  } = useGetApiSubscriptionsQuery();
  const [createApiSubscription] = useCreateApiSubscriptionMutation();
  const [deleteApiSubscription] = useDeleteApiSubscriptionMutation();
  const [createApiSubscriptionKey] = useCreateApiSubscriptionKeyMutation();
  const [deleteApiSubscriptionKey] = useDeleteApiSubscriptionKeyMutation();

  function createSubscription(api: Api) {
    createApiSubscription(
      {
        variables: {
          command: {
            api: api.name,
            plan: ptPlan,
          },
        },
      },
    ).then((_) => refetchSubscriptions());
  }

  function createSubscriptionKey(subscriptionId: string) {
    createApiSubscriptionKey(
      {
        variables: {
          subscriptionId,
        },
      },
    ).then((_) => refetchSubscriptions());
  }

  function deleteSubscription(id: string) {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete your subscription')}`)) {
      deleteApiSubscription(
        {
          variables: {
            id,
          },
        },
      ).then((_) => refetchSubscriptions());
    }
  }

  function deleteSubscriptionKey(id: string) {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete your API key')}`)) {
      deleteApiSubscriptionKey(
        {
          variables: {
            id,
          },
        },
      ).then((_) => refetchSubscriptions());
    }
  }

  function renderSubscription() {
    const subscription = subscriptionsData?.apiCatalog.userSubscriptions.find((s) => s.api.name === ptApiName);

    if (subscription) {
      return (
        <div>
          <section className="bg-white">
            <div className="mb-6 py-4 align-top">
              <h2 className="mb-5 inline-block text-4xl tracking-tight font-extrabold text-gray-900 ">
                Mijn Pientere Tuin sensordata
              </h2>
              <p>
                Hieronder vind je de gegevens van de API waarmee je toegang krijgt tot
                de data van jouw sensor. Heb je nog geen API-key (abonnement)
                aangevraagd dan kun je dat hieronder doen. Wil je geen gebruik meer
                maken van de API, dan kun je jouw abonnement of API-key ook zelf
                verwijderen.
              </p>

              <p>
                In de kennisbank vind je een uitgebreid artikel hoe je de API kunt gebruiken en bijvoorbeeld kunt integreren met een Home Assistant. Het artikel vind je hier:
                <a className="underline" href="https://help.goodcitysense.nl/portal/nl/kb/articles/kan-ik-mijn-eigen-sensordata-downloaden" target="_blank" rel="noreferrer"> https://help.goodcitysense.nl/portal/nl/kb/articles/kan-ik-mijn-eigen-sensordata-downloaden</a>
                .
              </p>
            </div>
          </section>
          <div>
            <h2>Pientere Tuinen API abonnement informatie:</h2>
            <div className="pb-4 flex items-center gap-x-1">
              Geabonneerd sinds
              {' '}
              <div className="font-bold inline">
                {format(parseISO(subscription.createdAt), 'dd-MM-y', { locale: nl })}
              </div>
              <Button
                type="button"
                color="failure"
                className="ml-4 p-0 inline"
                onClick={() => deleteSubscription(subscription.id)}
              >
                Verwijder abonnement
              </Button>
            </div>
          </div>
          <h2>Informatie over jouw API-key voor de API van Mijn Pientere Tuin:</h2>
          <div className="pb-4">

            {subscription.keys.length > 0 ? (
              <>
                <div className="flex items-center gap-x-4">
                  Jouw API key is:
                  {' '}
                  <div className="font-bold inline">
                    {subscription.keys[0].key}
                  </div>
                  <CopyToClipboardButton
                    color="info"
                    content={<>Kopieer API-key</>}
                    clipboardData={subscription.keys[0].key}
                    tooltipMessage="API-key gekopieerd"
                  />
                  <Button
                    type="button"
                    color="failure"
                    className="p-0 inline"
                    onClick={() => deleteSubscriptionKey(subscription.keys[0].id)}
                  >
                    Verwijder API-key
                  </Button>
                </div>
                <div>
                  <div className="font-bold text-xl">Jouw API verbruik: </div>
                  <ApiCatalogMeteringStatistics apiKey={subscription.keys[0].id} />
                </div>

                <div className="py-4">
                  <Link className="underline" to={PTOpenApiSpecRoute()}>Bekijk de mijn pientere tuin open api documentatie</Link>
                </div>
              </>
            ) : (
              <Button type="button" color="info" className="p-0" onClick={() => createSubscriptionKey(subscription.id)}>
                Generate key
              </Button>
            )}
          </div>
        </div>
      );
    }

    const api = apisData?.apiCatalog.apis.find((a: Api) => a.name === ptApiName);
    if (!api) {
      return '';
    }

    return (
      <div>
        <h2>Abonneer om je persoonlijke API key te krijgen voor jouw Pientere Tuinen data</h2>
        <Button type="button" color="info" className="btn mt-6 p-0" onClick={() => createSubscription(api)}>
          Abonneer
          {' '}
          {ptPlan}
        </Button>
      </div>
    );
  }

  return <>{renderSubscription()}</>;
}

export default PientereTuinenSubscriptionApp;
