import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useForm } from 'react-hook-form';
import { Button, Modal } from 'flowbite-react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  DeleteTreeCommand, DeleteTreeReason,
  Tree, useDeleteTreeMutation,
} from '../../../generated/gql/types';
import Dropdown, { DropdownOption } from './Dropdown';

function TreeDetails({ tree, closeDeletedHandler, editHandler }: TreeDetailsProps) {
  const { t } = useTranslation();

  const [deleteTree, { loading: loadingDelete }] = useDeleteTreeMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const notifySaved = () => toast.success('Uw boom verwijdering is ontvangen. Het duurt even voordat deze wijziging zichtbaar is.', { autoClose: 7000 });

  const {
    register: registerDelete,
    handleSubmit: handleDeleteSubmit,
  } = useForm<DeleteTreeCommand>({});
  const onDeleteSubmit = handleDeleteSubmit((deleteCommand) => {
    deleteTree({
      refetchQueries: [
        'SearchTrees',
      ],
      variables: {
        command: deleteCommand,
      },
    }).then(() => {
      closeDeletedHandler();
      notifySaved();
    });
  });

  function treeHasBenefits(value: Tree): boolean {
    if (value.benefits !== undefined && value.benefits !== null) {
      return value.benefits.yearlyCapturedCo2InKg !== undefined && value.benefits.yearlyCapturedPollutionInG !== undefined;
    }

    return false;
  }

  return (
    <>
      <Modal
        show={deleteModal}
        size="md"
        popup
        onClose={() => setDeleteModal(false)}
        className="!z-[9999]"
      >
        <Modal.Header />

        <Modal.Body>
          <div className="text-center">
            <FontAwesomeIcon icon={faExclamationCircle} size="3x" color="red" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Geef een reden op waarom je deze boom wilt verwijderen
            </h3>
            <div className="flex justify-center gap-4">
              <form className="flex flex-col" onSubmit={onDeleteSubmit}>
                <input type="hidden" {...registerDelete('id', { value: tree.id })} />
                <Dropdown
                  register={registerDelete('reason', {
                    required: true,
                    pattern: /^((?!undefined).)*$/,
                    value: undefined,
                  })}
                  options={Object.values(DeleteTreeReason).map((reason) => (
                    { name: t(reason), value: reason }
                  ) as DropdownOption) ?? []}
                />

                <div className="flex w-full gap-6 justify-between">
                  <Button
                    color="gray"
                    onClick={() => setDeleteModal(false)}
                  >
                    Annuleer
                  </Button>
                  <Button
                    color="failure"
                    type="submit"
                  >
                    Verwijder
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="flex w-full items-center">
        <div className="text-xl w-full">{tree.species?.name ? tree.species?.name : (tree.species?.scientificName ?? 'Onbekende Boom') }</div>
      </div>
      <div className="mt-1">
        <div className="font-bold">Kenmerken van deze boom:</div>
        <table>
          <tbody>
            <tr>
              <td>Latijnse naam: </td>
              <td>
                {tree.species?.scientificName ?? 'onbekend'}
              </td>
            </tr>
            <tr>
              <td>Lengte: </td>
              <td>
                {tree.sizes.heightClass ? (t(tree.sizes.heightClass)) : 'onbekend'}
              </td>
            </tr>
            <tr>
              <td>Stamdikte: </td>
              <td>
                {tree.sizes.trunkDiameterInCm ? `${tree.sizes.trunkDiameterInCm} cm` : 'onbekend'}
              </td>
            </tr>
            <tr>
              <td>Kroondiameter: </td>
              <td>
                {tree.sizes.crownDiameterClass ? (t(tree.sizes.crownDiameterClass)) : 'onbekend'}
              </td>
            </tr>
            <tr>
              <td>Kroonvolume: </td>
              <td>
                {tree.sizes?.crownVolumeInM3 ? `${tree.sizes?.crownVolumeInM3} m3` : 'onbekend'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {treeHasBenefits(tree) && (
        <div className="bg-[#e4f1db] flex gap-y-2 flex-col p-2 text-xs xl:text-base">
          <table className="border-separate border-spacing-2">
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon size="2xl" className="text-wc-blue" icon={icon({ name: 'seedling', family: 'classic', style: 'light' })} />
                </td>
                <td>
                  <div className="gap-x-1 flex items-center">
                    <div className="flex items-center">
                      Opgeslagen CO
                      <sub className="mr-1">2</sub>
                      {' '}
                      per jaar:
                    </div>
                    <div
                      className="font-bold"
                    >
                      {round((tree?.benefits?.yearlyCapturedCo2InKg ?? 0))}
                    </div>
                    <div className="font-bold">kilo</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon size="2xl" className="text-wc-blue" icon={icon({ name: 'hand-holding-droplet', family: 'classic', style: 'light' })} />
                </td>
                <td>
                  <div className="gap-x-1 flex items-center">
                    <div>Opgevangen water per jaar:</div>
                    <div
                      className="font-bold"
                    >
                      {round((tree?.benefits?.yearlyCapturedWaterInM3 ?? 0) * 1000)}
                    </div>
                    <div className="font-bold">liter</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon size="2xl" className="text-wc-blue" icon={icon({ name: 'leaf-heart', family: 'classic', style: 'light' })} />
                </td>
                <td>
                  <div className="gap-x-1 flex items-center">
                    <div className="whitespace-normal">
                      Weggenomen luchtvervuiling per jaar:
                      <div
                        className="font-bold inline-block"
                      >
                        {round(tree?.benefits?.yearlyCapturedPollutionInG ?? 0)}
                        {' '}
                        gram
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon size="2xl" className="text-wc-blue" icon={icon({ name: 'coins', family: 'classic', style: 'light' })} />
                </td>
                <td>
                  <div className="gap-x-1 flex items-center">
                    <div>Boomwaarde:</div>
                    <div className="font-bold">{tree?.benefits?.worthInEuro ?? 'Onbekend'}</div>
                    <div className="font-bold">euro</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {loadingDelete ? (
        <div className="flex justify-between">
          <Button type="button" className="btn self-start hover:bg-wc-hover-blue mt-1" disabled>
            {t('edit tree')}
          </Button>
          <Button className="self-end mt-1" color="failure" disabled>
            {t('delete tree')}
          </Button>
        </div>
      ) : (
        <div className="flex justify-between">
          <Button type="button" className="btn self-start hover:bg-wc-hover-blue mt-1" onClick={() => editHandler()}>
            {t('edit tree')}
          </Button>
          <Button className="self-end mt-1" color="failure" onClick={() => setDeleteModal(true)}>
            {t('delete tree')}
          </Button>
        </div>
      )}
    </>
  );
}

type TreeDetailsProps = {
  tree: Tree
  closeDeletedHandler: () => void
  editHandler: () => void
};

export default TreeDetails;
