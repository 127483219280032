import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../../../images/pientere_tuinen_logo_notext.png';
import { PTSensorRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';
import { defaultSoilType, findSoilTypeByKey } from '../../hooks/usePientereTuinenSoilTypes';
import { getProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import useLatestPientereTuinenAsset from '../../hooks/useLatestPientereTuinenAsset';
import Smiley from './Smiley';
import Loader from '../../Loader';

function LiveCard() {
  const { asset } = useLatestPientereTuinenAsset();
  const soilType = asset ? findSoilTypeByKey(getProperty(asset, PientereTuinenAssetProperty.SoilType)?.value ?? '') : defaultSoilType;

  return (
    <div
      className="gcscard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top"
    >
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left mt-2 text-2xl font-bold text-gray-900">
            Mijn Pientere Tuin
          </h4>
          <img src={logo} className="inline-right h-[70px]" alt="Pientere Tuinen Logo" />
        </div>

        <div className="h-full p-5 mb-5">
          {asset && asset?.id ? (
            <Smiley sensorId={asset?.id} soilType={soilType} />
          ) : (<Loader />)}
        </div>

        {asset && asset?.id ? (
          <Link
            to={PTSensorRoute(getProperty(asset, PientereTuinenAssetProperty.SensorId)?.value ?? '')}
            className="btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
          >
            <span className="flex items-center rounded-md text-sm px-4 py-2">
              Alle details
              <ArrowRight />
            </span>
          </Link>
        ) : (
          <button
            type="button"
            className="opacity-50 disabled btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
          >
            <span className="flex items-center rounded-md text-sm px-4 py-2">
              Alle details
              <ArrowRight />
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default LiveCard;
