import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import {
  eachDayOfInterval, format, isSameDay, subDays,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import {
  useGetApiCatalogMeteringQuery,
} from '../../../generated/gql/types';

function CustomTooltipTemp({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {payload[0].payload.day}
        </p>
        <p className="label">
          Opvragingen:
          &nbsp;
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
}

function ApiCatalogMeteringStatistics({ apiKey }: ApiCatalogMeteringStatisticsProps) {
  const { data: apiData } = useGetApiCatalogMeteringQuery({
    fetchPolicy: 'network-only',
    context: { clientName: 'cube' },
    variables: {
      where: {
        key_id: {
          equals: apiKey,
        },
      },
    },
  });

  function formatForGraph(day: Date, calls: number): Record {
    return {
      day: format(day, 'd MMM y', { locale: nl }),
      value: calls,
    } as Record;
  }

  const formatDate = (date: any) => date.day;

  const startDate = new Date();
  const dateRange = eachDayOfInterval({
    start: subDays(startDate, 30),
    end: startDate,
  });

  function getDataForDate(date: Date): Record | undefined {
    const result = apiData?.cube.find((r) => isSameDay(date, new Date(r.api_catalog_metering?.day?.day)));

    return formatForGraph(date, result ? (result.api_catalog_metering.sum_calls ?? 0) : 0);
  }

  return (
    <div className="rounded-lg border border-gray-200 bg-white mb-6 h-[300px]">
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={400}
          data={dateRange.map((d) => getDataForDate(d))}
          margin={{
            top: 20, right: 5, bottom: 20, left: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <YAxis />
          <XAxis dataKey={formatDate} minTickGap={20} />
          <Tooltip content={<CustomTooltipTemp />} />
          <Legend />
          <Bar dataKey="value" fill="#12678f" name="Opvragingen per dag" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

type Record = {
  value: number,
  day: string,
};

export type ApiCatalogMeteringStatisticsProps = {
  apiKey: string
};

export default ApiCatalogMeteringStatistics;
