import React, { useEffect, useState } from 'react';
import {
  parseISO, setMinutes, setSeconds, subDays, subYears,
} from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import PientereTuinenFooter from './PientereTuinenFooter';
import logo from '../../../images/pientere_tuinen_logo.png';
import ArrowRight from '../../elements/ArrowRight';
import {
  findSoilTypeByKey,
  SoilType,
} from '../../hooks/usePientereTuinenSoilTypes';
import {
  ApiSubscriptionsRoute, DashboardRoute, PTTreeMonitorRoute,
} from '../../Routes';
import MyGardenForm from './MyGardenForm';
import { getProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import Charts from './Charts';
import BoxElement from './BoxElement';
import useMyRoles from '../../hooks/useMyRoles';
import { RoleUser } from '../../../generated/gql/types';
import usePientereTuinenAssetBySerial from '../../hooks/usePientereTuinenAssetBySerial';

function PientereTuinenApp() {
  const params = useParams();
  const { asset, loading: assetLoading } = usePientereTuinenAssetBySerial({ serial: params.serial ?? '' });
  const [soilType, setSoilType] = useState<SoilType | undefined>(undefined);
  const myRoles = useMyRoles();

  useEffect(() => {
    if (asset && !assetLoading) {
      const soilTypeProperty = getProperty(asset, PientereTuinenAssetProperty.SoilType);
      setSoilType(findSoilTypeByKey(soilTypeProperty?.value!));
    }
  }, [asset, assetLoading]);

  function getStartOfMeasurements() {
    if (asset?.createdAt) {
      const start = setSeconds(setMinutes(parseISO(asset?.createdAt) ?? setSeconds(setMinutes(subDays(new Date(), 7), 0), 0), 0), 0);
      // Max 1 year back
      const startLimit = subYears(new Date(), 1);
      if (start < startLimit) {
        return startLimit;
      }

      return start;
    }

    return setSeconds(setMinutes(subDays(new Date(), 7), 0), 0);
  }

  return (
    <div className="mx-2.5 md:mx-0">
      <section className="bg-white">
        <div className="p-4 align-top">
          <div className="flex items-center mb-5">
            <Link to={DashboardRoute()}>
              <img src={logo} className="h-[65px] mr-4 border border-gray-300" alt="Pientere Tuinen Logo" />
            </Link>
            <h2 className="inline-block text-4xl tracking-tight font-extrabold text-gray-900 ">
              Mijn Pientere Tuin
            </h2>
          </div>
          <p>
            Dit is jouw persoonlijke omgeving waar je o.a. gegevens uit de bodemsensor ziet.
            Ook kun je direct naar de Kennisbank of Helpdesk. In het begin zal de hoeveelheid
            informatie nog beperkt zijn. We moeten eerst een aantal maanden data verzamelen
            voordat we jou meer inzicht kunnen bieden.
            <br />
            Gedurende het project zul je hier dan ook steeds meer informatie vinden.
            Met de nieuwsbrief zullen we je op de hoogte houden van alle nieuwe ontwikkelingen.
          </p>
        </div>
      </section>

      <section>
        <div className="w-full md:w-2/3 inline-block p-4 align-top">
          {asset && soilType ? (
            <Charts
              sensorIds={[asset?.id]}
              soilType={soilType}
              startDateOfMeasurements={getStartOfMeasurements()}
            />
          ) : (
            <>
              <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
                <div className="m-5 h-[400px]" />
              </div>
              <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
                <div className="m-5 h-[400px]" />
              </div>
            </>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
            <BoxElement
              title="Support"
              description="Heb je een vraag? Kijk eerst in de Kennisbank. Daar vind je instructies,
                  achtergrondartikelen en tuintips. Vind je geen antwoord op jouw vraag? Of heb je een idee of suggestie?
                  Stuur dan een reactie naar de Helpdesk. We zijn blij met elke reactie."
            >
              { process.env.REACT_APP_ENVIRONMENT === 'production' ? (
                <a
                  href="https://help.goodcitysense.nl/portal/nl/signin"
                  target="_blank"
                  className="mb-5 border bg-sky-300 border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
                  rel="noreferrer"
                >
                  <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
                    Community Portaal
                    <ArrowRight />
                  </span>
                </a>
              ) : (
                <a
                  href="https://goodcitysense1711704433706.zohodesk.eu/portal/en/signin"
                  target="_blank"
                  className="mb-5 border bg-sky-300 border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
                  rel="noreferrer"
                >
                  <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
                    Community Portaal (Sandbox)
                    <ArrowRight />
                  </span>
                </a>
              )}

              <a
                href="mailto:support@goodcitysense.nl"
                target="_blank"
                className="border bg-sky-300 border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
                rel="noreferrer"
              >
                <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
                  Mail support
                  <ArrowRight />
                </span>
              </a>
            </BoxElement>
            {process.env.REACT_APP_API_SUBSCRIPTIONS_ENABLED === 'true' && (
              <BoxElement
                title="Mijn Api"
                description="Zelf aan de slag met je sensor data?"
              >
                <Link
                  to={ApiSubscriptionsRoute()}
                  className="mb-5 border bg-sky-300 border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
                >
                  <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
                    Mijn Api
                    <ArrowRight />
                  </span>
                </Link>
              </BoxElement>
            )}
          </div>
        </div>
        <div className="col2 w-full md:w-1/3 inline-block p-4 align-top">
          <MyGardenForm />

          {process.env.REACT_APP_TREE_MONITOR_ENABLED === 'true' && (myRoles?.userRoles ?? []).find((r) => r === RoleUser.UserTreeMonitorUser) && (
            <BoxElement
              title="Bomen rondom jouw sensor"
              description="Bekijk de bomen die rondom de sensor in jouw tuin staan. Vul zelf de gegevens aan. En krijg inzicht in de groene baten van bomen."
            >
              <Link
                to={PTTreeMonitorRoute(params.serial)}
                className="mb-5 border bg-sky-300 border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 m-5 text-center font-medium focus:z-10 rounded-lg"
              >
                <span className="flex items-center font-bold rounded-md text-sm px-4 py-2">
                  Bekijk de bomen
                  <ArrowRight />
                </span>
              </Link>
            </BoxElement>
          )}
        </div>
      </section>
      <PientereTuinenFooter />
    </div>
  );
}

export default PientereTuinenApp;
