/* eslint-disable react/style-prop-object */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { LatLng } from 'leaflet';
import { toast } from 'react-toastify';
import {
  Tree,
  useUpdateTreeMutation,
} from '../../../generated/gql/types';
import TreeForm, { TreeFormData } from './TreeForm';

function TreeEdit({ tree, newCoordinates, closeHandler }: TreeEditProps) {
  const { t } = useTranslation();
  const [updateTree, { loading, error }] = useUpdateTreeMutation();
  const notifySaved = () => toast.success('Uw boom wijziging is ontvangen. Het duurt even voordat deze wijziging zichtbaar is.', { autoClose: 7000 });

  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TreeFormData>({
    defaultValues: {
      inputSpecies: {
        speciesCode: tree.species?.code,
      },
    },
  });

  const onSubmit = handleSubmit((formData) => {
    if (!formData.id) {
      return;
    }

    updateTree({
      refetchQueries: [
        'SearchTrees',
      ],
      variables: {
        command: {
          id: formData.id,
          inputSpecies: formData.inputSpecies,
          heightClass: formData.heightClass,
          crownDiameterClass: formData.crownDiameterClass,
          latitude: newCoordinates?.lat ?? tree.latitude,
          longitude: newCoordinates?.lng ?? tree.longitude,
        },
      },
    }).then(() => {
      closeHandler();
      notifySaved();
    }).catch((e) => {
      Sentry.captureMessage(e.toString(), 'error');
    });
  });

  return (
    <form className="flex flex-col min-h-full" onSubmit={onSubmit}>

      <TreeForm tree={tree} register={register} unregister={unregister} errors={errors} setValue={setValue} />

      <div className="flex-col grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="mb-2 block">
          <div className="font-bold text-gray-900">Latitude</div>
          <div>{newCoordinates?.lat ?? tree.latitude}</div>
        </div>
        <div className="mb-2 block">
          <div className="font-bold text-gray-900">Longitude</div>
          <div>{newCoordinates?.lng ?? tree.longitude}</div>
        </div>
      </div>

      {(loading) ? (
        <div className="flex flex-row justify-between">
          <Button className="btn" disabled>
            {t('cancel')}
          </Button>
          <Button className="btn" type="submit" disabled>
            {t('save changes')}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row justify-between">
          <Button className="btn" onClick={() => closeHandler()}>
            {t('cancel')}
          </Button>
          <Button className="btn" type="submit">
            {t('save changes')}
          </Button>
        </div>
      )}

      {error && (
        <div className="text-red-600 my-2">
          Sorry er is iets misgegaan met het opslaan van de nieuwe boom, probeer het later opnieuw.
        </div>
      )}
    </form>
  );
}

type TreeEditProps = {
  tree: Tree
  newCoordinates: LatLng | undefined
  closeHandler: () => void
};

export default TreeEdit;
