import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Asset, useGetPientereTuinenAssetsForUserQuery,
} from '../../generated/gql/types';

function useLatestPientereTuinenAsset(): AssetResponse {
  const { loading, error, data } = useGetPientereTuinenAssetsForUserQuery({
    context: { clientName: 'asset' },
    fetchPolicy: 'network-only',
  });

  const [asset, setAsset] = useState<Asset | undefined>(undefined);

  useEffect(() => {
    if (!error && data && data.pientereTuinenAssetsForUser.data != null
      && data.pientereTuinenAssetsForUser.data.length > 0) {
      setAsset(
        data.pientereTuinenAssetsForUser.data[data.pientereTuinenAssetsForUser.data.length - 1] as Asset,
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      Sentry.captureMessage(error.message, 'error');
    }
  }, [error]);

  return { asset, loading, error: (error ? error?.message : undefined) };
}

type AssetResponse = {
  loading: boolean,
  asset: Asset | undefined,
  error: string | undefined
};

export default useLatestPientereTuinenAsset;
