import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  MapContainer, Marker, Popup, TileLayer, useMapEvent,
} from 'react-leaflet';
import {
  LatLng, latLngBounds, LatLngBounds,
} from 'leaflet';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Badge } from 'flowbite-react';
import logo from '../../../images/pientere_tuinen_logo_notext.png';
import { PTSensorRoute, PTTreeMonitorRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';
import {
  getProperty, latLongFromProperty, parseBoolean, PientereTuinenAssetProperty, translateProperty,
} from '../../../AssetHelpers';
import { Asset, useGetPientereTuinenAssetsForUserQuery } from '../../../generated/gql/types';
import Loader from '../../Loader';

function RecenterMap({ bounds }: RecenterMapProps) {
  const map = useMapEvent('popupclose', () => {
    if (map !== undefined) {
      map.fitBounds(bounds);
    }
  });

  return null;
}

function MultipleLiveCard() {
  const { loading, data } = useGetPientereTuinenAssetsForUserQuery({
    context: { clientName: 'asset' },
    fetchPolicy: 'network-only',
  });
  const [
    assetsWithCoordinates,
    setAssetsWithCoordinates,
  ] = useState<AssetWithCoordinates[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.pientereTuinenAssetsForUser.data != null && data.pientereTuinenAssetsForUser.data.length > 0) {
      setAssetsWithCoordinates(
        data.pientereTuinenAssetsForUser.data.map((a) => (
          {
            asset: a,
            location: latLongFromProperty(
              getProperty(a, PientereTuinenAssetProperty.Coordinates),
            ),
          } as AssetWithCoordinates
        )),
      );
    }
  }, [data, loading]);

  return (
    <div className="gcsdoublecard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 md:w-[40rem] shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left mt-2 text-2xl font-bold text-gray-900">
            Mijn Pientere Tuinen+
          </h4>
          <img src={logo} className="inline-right h-[70px]" alt="Pientere Tuinen Logo" />
        </div>

        <div className="h-full px-5 pt-5">
          <div className="rounded-lg mb-2 border border-gray-300">
            {(!loading && assetsWithCoordinates.length > 0) ? (
              <MapContainer
                style={{ height: '400px' }}
                center={latLngBounds(assetsWithCoordinates.map((a) => a.location)).getCenter()}
                minZoom={8}
                scrollWheelZoom
                zoomControl={false}
                bounds={latLngBounds(assetsWithCoordinates.map((a) => a.location))}
                maxBounds={new LatLngBounds(
                  new LatLng(50.6309883, 3.0625035),
                  new LatLng(53.7181849, 7.2928109),
                )}
              >
                <RecenterMap bounds={latLngBounds(assetsWithCoordinates.map((a) => a.location))} />
                <TileLayer
                  attribution=""
                  url={`${process.env.REACT_APP_WMTS_URL}/topoplus_achtergrond/wm_19/{z}/{x}/{y}.jpeg`}
                />

                {assetsWithCoordinates.map((a) => (
                  <Marker
                    key={a.asset?.id}
                    position={a.location}
                  >
                    <Popup minWidth={90}>
                      <h2 className="pb-2 text-base flex items-center font-bold gap-x-2">
                        Pientere Tuinen sensor
                        <Badge color="success">
                          {getProperty(a.asset, PientereTuinenAssetProperty.SensorId)?.value}
                        </Badge>
                      </h2>
                      <ul>
                        <li className="flex items-center pb-0.5">
                          {t('garden size')}
                          {': '}
                          {translateProperty(a.asset, PientereTuinenAssetProperty.GardenSize, t)}
                        </li>
                        <li className="flex items-center pb-0.5">
                          {t('garden orientation')}
                          {': '}
                          {translateProperty(a.asset, PientereTuinenAssetProperty.GardenOrientation, t)}
                        </li>
                        <li className="flex items-center pb-0.5">
                          {t('garden hardening')}
                          {': '}
                          {translateProperty(a.asset, PientereTuinenAssetProperty.GardenHardening, t)}
                        </li>
                        <li className="flex items-center">
                          {t('soil type')}
                          {': '}
                          {translateProperty(a.asset, PientereTuinenAssetProperty.SoilType, t)}
                        </li>

                        <li className="flex items-center pt-2">
                          <Link
                            to={PTTreeMonitorRoute(getProperty(a.asset, PientereTuinenAssetProperty.SensorId)?.value ?? '')}
                            className="underline flex gap-x-1 items-center !text-[#409826]"
                          >
                            <FontAwesomeIcon className="cursor-pointer" icon={icon({ name: 'tree-alt' })} />
                            {t('view green benefits')}
                          </Link>
                        </li>

                        <li className="flex items-center pt-2">
                          <Link
                            to={PTSensorRoute(getProperty(a.asset, PientereTuinenAssetProperty.SensorId)?.value ?? '')}
                            className="underline flex gap-x-1 items-center"
                          >
                            <FontAwesomeIcon className="cursor-pointer" icon={icon({ name: 'chart-line' })} />
                            {t('view data')}
                          </Link>
                        </li>

                        {(parseBoolean(getProperty(a.asset, PientereTuinenAssetProperty.NotificationsEnabled)?.value!)) ? (
                          <li className="flex items-center gap-x-1 pt-2">
                            <FontAwesomeIcon icon={icon({ name: 'bell-on' })} />
                            {t('notifications on')}
                          </li>
                        ) : (
                          <li className="flex items-center gap-x-1 pt-2">
                            <FontAwesomeIcon icon={icon({ name: 'bell-slash' })} />
                            {t('notifications off')}
                          </li>
                        )}

                      </ul>
                    </Popup>
                  </Marker>
                ))}

              </MapContainer>
            ) : (
              <Loader />
            )}
          </div>
        </div>

        <button
          type="button"
          className="opacity-50 disabled btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Naar grotere kaart (coming soon...)
            <ArrowRight />
          </span>
        </button>

      </div>
    </div>
  );
}

type AssetWithCoordinates = {
  asset: Asset
  location: LatLng
};

type RecenterMapProps = {
  bounds: LatLngBounds
};

export default MultipleLiveCard;
