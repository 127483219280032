import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Label, Table, TextInput,
} from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import {
  CreateSensorRegistrationTokensForUserCommand,
  useCreateSensorRegistrationTokensForUserMutation,
  useGetOrganizationsQuery,
  useGetSensorRegistrationTokenForUserQuery,
  useGetUserDetailsQuery,
  User,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import AssetTable from './AssetTable';

function UserAssets() {
  const params = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm<CreateSensorRegistrationTokensForUserCommand>();

  const [createTokens, { loading: createTokensLoading, error: createTokensError }] = useCreateSensorRegistrationTokensForUserMutation();

  const { data: organizationsData, loading: organizationsLoading } = useGetOrganizationsQuery();
  const { data: userData, loading: userDataLoading, error: userDataError } = useGetUserDetailsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        id: params.userId ?? '',
      },
    },
  );

  const { data: tokensData, loading: tokensLoading, refetch: refetchTokens } = useGetSensorRegistrationTokenForUserQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        userId: params.userId ?? '',
      },
    },
  );

  const onSubmit = handleSubmit((createTokensCommand) => {
    if (createTokensCommand.amount === 0) {
      return;
    }

    createTokens({
      variables: {
        command: {
          userId: createTokensCommand.userId,
          orgId: createTokensCommand.orgId,
          amount: parseInt((createTokensCommand.amount ?? 1).toString(), 10),
        },
      },
    }).then(() => refetchTokens());
  });

  function setOrganization(e: SingleValue<{ label: string, value: string }>) {
    if (e) {
      setValue('orgId', e.value);
    }
  }

  if (userDataError) {
    return <div>{userDataError.message}</div>;
  }

  if (!userData?.user || userDataLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl gap-y-4">
      <div className="text-xl flex gap-x-1">
        <div>Assets for user</div>
        <div className="capitalize-first font-bold">
          {userData.user.firstname}
        </div>
        <div className="capitalize-first font-bold">
          {userData.user.lastname}
        </div>
        <div className="font-bold">
          (
          {userData.user.email}
          )
        </div>
      </div>
      <Button type="button" color="info" className="btn p-0 mb-4" onClick={() => navigate(-1)}>
        <FontAwesomeIcon
          icon={icon({ name: 'arrow-left', family: 'classic', style: 'regular' })}
          size="xl"
        />
      </Button>
      <AssetTable user={userData?.user as User} />

      <div className="text-xl flex gap-x-1">
        <div>Available sensor tokens</div>
      </div>
      <div>
        {tokensData && !tokensLoading && tokensData?.sensorRegistrationTokensForUser?.length > 0 ? (
          <Table hoverable className="p-3">
            <Table.Head>
              <Table.HeadCell>
                #
              </Table.HeadCell>
              <Table.HeadCell>
                OrgId
              </Table.HeadCell>
              <Table.HeadCell>
                UpdatedAt
              </Table.HeadCell>
              <Table.HeadCell>
                CreatedAt
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {tokensData.sensorRegistrationTokensForUser.map((token, index) => (
                <Table.Row
                  key={crypto.randomUUID()}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell>
                    {index + 1}
                  </Table.Cell>
                  <Table.Cell>
                    {token.organization?.orgId}
                  </Table.Cell>
                  <Table.Cell>
                    {format(parseISO(token.updatedAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                  </Table.Cell>
                  <Table.Cell>
                    {format(parseISO(token.createdAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <div>No available open sensor registration tokens</div>
        )}
      </div>
      <div>
        <form onSubmit={onSubmit}>
          { createTokensError && (
            <div>{createTokensError.message}</div>
          )}
          <input type="hidden" {...register('userId', { value: params.userId })} />
          <div>
            <div className="mb-2 block text-sm">
              Create new sensor registrations tokens so users can register a sensor. A token is temporary used to link a sensor to an organization.
              After registration the token is removed. This creations process does not check if a user is eligible for a sensor (has signed the sensor agreement in zoho)
            </div>
            <Select
              required
              isSearchable
              placeholder="Search organization"
              isLoading={organizationsLoading}
              options={organizationsData?.organizations?.map((o) => ({
                label: o.orgId,
                value: o.orgId,
              }))}
              className="w-full"
              classNamePrefix="select"
              onChange={(e) => setOrganization(e)}
            />
          </div>
          <div className="mb-2 block">
            <Label
              htmlFor="amount"
              value=""
            />
          </div>
          <TextInput
            type="number"
            placeholder="1"
            required
            className="mb-2"
            {...register('amount')}
          />

          {createTokensLoading ? (
            <Button type="submit" disabled>
              Create tokens
            </Button>
          ) : (
            <Button type="submit">
              Create tokens
            </Button>
          )}
        </form>
      </div>
    </div>
  );
}

export default UserAssets;
