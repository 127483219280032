import React from 'react';
import Loader from '../../Loader';
import ErrorCard from '../../ErrorCard';
import { useGetPientereTuinenAssetsForUserQuery } from '../../../generated/gql/types';
import StatusCard from './StatusCard';
import MultipleLiveCard from './MultipleLiveCard';

function MultipleSensorStatusCard() {
  const { loading, error, data } = useGetPientereTuinenAssetsForUserQuery({
    context: { clientName: 'asset' },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorCard moduleName="Pientere Tuinen" />;
  }

  if (data && data.pientereTuinenAssetsForUser.data != null && data.pientereTuinenAssetsForUser.data.length > 1) {
    return <MultipleLiveCard />;
  }

  return <StatusCard />;
}

export default MultipleSensorStatusCard;
