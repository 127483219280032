export const LandingRoute = () => '/';
export const LoadingRoute = () => '/loading';
export const DashboardRoute = () => '/dashboard';
export const OldRedirectToDashboardRoutes = () => ['/pientere-tuinen', '/pientere-tuinen/bomen-in-de-buurt'];

export const PTRegisterRoute = () => '/pientere-tuinen/register';
export const PTSensorRoute = (serial?: string) => (serial ? `/pientere-tuinen/${serial}` : '/pientere-tuinen/:serial');
export const PTTreeMonitorRoute = (serial?: string) => (serial ? `/pientere-tuinen/${serial}/bomen-in-de-buurt` : '/pientere-tuinen/:serial/bomen-in-de-buurt');
export const PTOpenApiSpecRoute = () => '/api-subscriptions/open-api/mijn-pientere-tuin';
export const ApiSubscriptionsRoute = () => '/api-subscriptions';

// admin
export const ImportZohoUsersRoute = () => '/admin/zoho-import';
export const CreateSensorRegistrationTokensRoute = () => '/admin/create-sensor-registration-tokens';
export const SendVerificationEmailRoute = () => '/admin/send-verification-email';
export const OrganizationManagementRoute = () => '/admin/organization-management';
export const OrganizationUsersRoute = (orgId?: string) => (orgId ? `/admin/organization-management/${orgId}/users` : '/admin/organization-management/:orgId/users');
export const AssetManagementRoute = () => '/admin/asset-management';

export const UserManagementRoute = () => '/admin/user-management';
export const UserRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}` : '/admin/user-management/user/:userId');
export const UserRolesRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}/roles` : '/admin/user-management/user/:userId/roles');
export const UserAssetsRoute = (userId?: string) => (userId ? `/admin/user-management/user/${userId}/assets` : '/admin/user-management/user/:userId/assets');
