import React, { useState, useEffect } from 'react';
import {
  setMinutes, setSeconds, subHours,
} from 'date-fns';
import {
  faFaceSmile, faFaceMeh, faFaceFrown,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHourlyPientereTuinenSensorData from '../../hooks/useHourlyPientereTuinenSensorData';
import useLatestPientereTuinenAsset from '../../hooks/useLatestPientereTuinenAsset';
import { SoilType } from '../../hooks/usePientereTuinenSoilTypes';

function Smiley({ sensorId, soilType }: SmileyProps) {
  const [currentTemperature, setCurrentTemperature] = useState(0);
  const [currentHumidity, setCurrentHumidity] = useState(0);

  const fromDate = setSeconds(setMinutes(subHours(new Date(), 8), 0), 0);
  const toDate = setSeconds(setMinutes(new Date(), 0), 0);

  const { asset } = useLatestPientereTuinenAsset();
  const sensorDataHour = useHourlyPientereTuinenSensorData(
    { from: fromDate, to: toDate, sensorIds: [sensorId] },
  );

  useEffect(() => {
    if (sensorDataHour.humid.length > 0) {
      setCurrentHumidity(sensorDataHour.humid[sensorDataHour.humid.length - 1].value);
    }

    if (sensorDataHour.temp.length > 0) {
      setCurrentTemperature(sensorDataHour.temp[sensorDataHour.temp.length - 1].value);
    }
  }, [sensorDataHour]);

  function renderHumidItem(humid: number) {
    if (sensorDataHour.humid.length <= 0 || !asset) {
      return (
        <div className="rounded-lg p-5 border border-gray-300">
          <div className="text-gray-900 text-center">
            <h5 className="text-2xl font-bold text-gray-900 ">
              Vochtigheid bodem
            </h5>
            <span className="text-[1rem] leading-[6rem] font-extrabold">
              Nog geen data
            </span>
          </div>
        </div>
      );
    }

    if (humid >= soilType.default_norm_min && humid <= soilType.default_norm_max) { // green
      return (
        <div className="rounded-lg bg-green-100 p-5 border border-gray-300">
          <div className="text-gray-900 text-center">
            <h5 className="text-2xl font-bold text-gray-900 ">
              Vochtigheid bodem
            </h5>
            <span className="text-[5.5rem] leading-[6rem] font-extrabold">
              {`${humid}%`}
            </span>
            <span className="ml-1 text-green-500 inline-block">
              <FontAwesomeIcon icon={faFaceSmile} size="4x" />
            </span>
          </div>
        </div>
      );
    }

    if (humid >= soilType.wilting_point && humid <= soilType.default_norm_min) { // orange
      return (
        <div className="rounded-lg bg-orange-100 p-5 border border-gray-300">
          <div className="text-gray-900 text-center">
            <h5 className="text-2xl font-bold text-gray-900 ">
              Vochtigheid bodem
            </h5>
            <span className="text-[5.5rem] leading-[6rem] font-extrabold">
              {`${humid}%`}
            </span>
            <span className="ml-1 text-orange-500 inline-block">
              <FontAwesomeIcon icon={faFaceMeh} size="4x" />
            </span>
          </div>
        </div>
      );
    }

    return ( // red
      <div className="rounded-lg bg-red-100 p-5 border border-gray-300">
        <div className="text-gray-900 text-center">
          <h5 className="text-2xl font-bold text-gray-900 ">
            Vochtigheid bodem
          </h5>
          <span className="text-[5.5rem] leading-[6rem] font-extrabold">
            {`${humid}%`}
          </span>
          <span className="ml-1 text-red-500 inline-block">
            <FontAwesomeIcon icon={faFaceFrown} size="4x" />
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="rounded-lg p-5 mb-2 border border-gray-300">
        <div className="text-gray-900 text-center">
          <h5 className="text-2xl font-bold text-gray-900">
            Bodem&shy;temperatuur
          </h5>
          {sensorDataHour.temp.length > 0 ? (
            <span className="text-[5.5rem] leading-[6rem] font-extrabold">
              {`${currentTemperature}\u00B0`}
            </span>
          ) : (
            <span className="text-[1rem] leading-[6rem] font-extrabold">
              Nog geen data
            </span>
          )}
        </div>
      </div>
      {renderHumidItem(currentHumidity)}
    </>
  );
}

type SmileyProps = {
  sensorId: string
  soilType: SoilType
};

export default Smiley;
