import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import React from 'react';
import './index.css';
import { OidcSecure } from '@axa-fr/react-oidc';
import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import _ from 'lodash';
import { VanillaOidc } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc';
import Layout from './components/Layout';
import Landing from './components/Landing';
import Dashboard from './components/Dashboard';
import PientereTuinenApp from './components/modules/pientere-tuinen/PientereTuinenApp';
import LoadingPage from './components/LoadingPage';
import {
  ImportZohoUsersRoute,
  DashboardRoute,
  LandingRoute,
  LoadingRoute,
  PTRegisterRoute,
  SendVerificationEmailRoute,
  UserManagementRoute,
  OrganizationManagementRoute,
  ApiSubscriptionsRoute,
  PTOpenApiSpecRoute,
  PTTreeMonitorRoute,
  UserRoute,
  UserRolesRoute,
  UserAssetsRoute,
  CreateSensorRegistrationTokensRoute,
  OrganizationUsersRoute, AssetManagementRoute, PTSensorRoute, OldRedirectToDashboardRoutes,
} from './components/Routes';
import ImportZohoUsersApp from './components/modules/admin/ImportZohoUsersApp';
import SentVerificationEmailApp from './components/modules/admin/SentVerificationEmailApp';
import UserManagementApp from './components/modules/admin/UserManagementApp';
import OrganizationManagementApp from './components/modules/admin/OrganizationManagementApp';
import ApiCatalogApp from './components/modules/api-subscriptions/ApiCatalogApp';
import PientereTuinenOpenApiSpec from './components/modules/api-subscriptions/PientereTuinenOpenApiSpec';
import TreeMonitorApp from './components/modules/pientere-tuinen/TreeMonitorApp';
import UserRolesEdit from './components/modules/admin/UserRolesEdit';
import UserAssets from './components/modules/admin/UserAssets';
import UserEdit from './components/modules/admin/UserEdit';
import CreateSensorRegistrationTokensApp from './components/modules/admin/CreateSensorRegistrationTokensApp';
import RegisterApp from './components/modules/pientere-tuinen/RegisterApp';
import OrganizationUsersApp from './components/modules/admin/OrganizationUsersApp';
import AssetManagementApp from './components/modules/admin/AssetManagementApp';

export default function App() {
  const getOidc = VanillaOidc.get;

  const authLink = setContext((request, { headers }) => {
    const oidc = getOidc();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${oidc.tokens?.accessToken}`,
      },
    };
  });

  const assetLink = new RestLink({
    uri: process.env.REACT_APP_ASSET_API_URL,
    fieldNameNormalizer: (key: string) => _.camelCase(key),
  });

  const servicePortalApiGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_SERVICE_PORTAL_API_URL,
  });

  const cubeGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_CUBE_API_URL,
  });

  const client = new ApolloClient({
    link: authLink.concat(ApolloLink.split(
      (operation) => operation.getContext().clientName === 'asset',
      assetLink,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'cube',
        cubeGraphqlLink,
        servicePortalApiGraphqlLink,
      ),
    )),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path={LandingRoute()} element={<Layout />}>
            <Route index element={<Landing />} />
            <Route
              path={DashboardRoute()}
              element={(
                <OidcSecure callbackPath={DashboardRoute()}>
                  <Dashboard />
                </OidcSecure>
              )}
            />
            {OldRedirectToDashboardRoutes().map((oldRoute) => (
              <Route
                key={oldRoute}
                path={oldRoute}
                element={(
                  <OidcSecure>
                    <Navigate to={DashboardRoute()} replace />
                  </OidcSecure>
                )}
              />
            ))}
            <Route path={PTSensorRoute()} element={<OidcSecure><PientereTuinenApp /></OidcSecure>} />
            <Route path={PTRegisterRoute()} element={<OidcSecure><RegisterApp /></OidcSecure>} />
            <Route path={PTOpenApiSpecRoute()} element={<OidcSecure><PientereTuinenOpenApiSpec /></OidcSecure>} />

            {process.env.REACT_APP_TREE_MONITOR_ENABLED === 'true' && (
              <Route
                path={PTTreeMonitorRoute()}
                element={<OidcSecure><TreeMonitorApp /></OidcSecure>}
              />
            )}

            {process.env.REACT_APP_API_SUBSCRIPTIONS_ENABLED === 'true' && (
              <Route
                path={ApiSubscriptionsRoute()}
                element={<OidcSecure><ApiCatalogApp /></OidcSecure>}
              />
            )}

            <Route path={LoadingRoute()} element={<LoadingPage />} />

            <Route
              path={ImportZohoUsersRoute()}
              element={<OidcSecure><ImportZohoUsersApp /></OidcSecure>}
            />
            <Route
              path={SendVerificationEmailRoute()}
              element={<OidcSecure><SentVerificationEmailApp /></OidcSecure>}
            />
            <Route
              path={CreateSensorRegistrationTokensRoute()}
              element={<OidcSecure><CreateSensorRegistrationTokensApp /></OidcSecure>}
            />

            <Route
              path={UserManagementRoute()}
              element={<OidcSecure><UserManagementApp /></OidcSecure>}
            />
            <Route path={UserRoute()} element={<OidcSecure><UserEdit /></OidcSecure>} />
            <Route path={UserRolesRoute()} element={<OidcSecure><UserRolesEdit /></OidcSecure>} />
            <Route path={UserAssetsRoute()} element={<OidcSecure><UserAssets /></OidcSecure>} />

            <Route
              path={OrganizationManagementRoute()}
              element={<OidcSecure><OrganizationManagementApp /></OidcSecure>}
            />
            <Route
              path={AssetManagementRoute()}
              element={<OidcSecure><AssetManagementApp /></OidcSecure>}
            />

            <Route
              path={OrganizationUsersRoute()}
              element={<OidcSecure><OrganizationUsersApp /></OidcSecure>}
            />
            <Route path="*" element={<Landing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}
