import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button, Label, Table, TextInput,
} from 'flowbite-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  CreateOrganizationCommand,
  useCreateOrganizationMutation, useGetOrganizationsQuery,
} from '../../../generated/gql/types';
import { OrganizationUsersRoute } from '../../Routes';

function OrganizationManagementApp() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrganizationCommand>();

  const {
    data: organizations, loading: organizationsLoading, refetch,
  } = useGetOrganizationsQuery({ fetchPolicy: 'network-only' });

  const [createOrganization, {
    data: createOrganizationResponse, loading: createOrganizationLoading, error: organizationCreateError,
  }] = useCreateOrganizationMutation();

  const onSubmit = handleSubmit((command) => {
    createOrganization({
      variables: {
        command,
      },
    }).then((_) => refetch());
  });

  const organizationDoesNotExist = (value: string) => (organizations ? (organizations.organizations.find((o) => o.orgId === value) === undefined) : false);

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full text-4xl tracking-tight font-extrabold text-gray-900"
        >
          Organization management
        </h2>

        <p>
          Create and view current available organizations
        </p>
      </div>

      {!organizationsLoading && organizations ? (
        <>
          <h2 className="pb-2 text-2xl font-bold">
            Organizations (
            {organizations.organizations.length}
            )
          </h2>
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>
                Id
              </Table.HeadCell>
              <Table.HeadCell>
                Name
              </Table.HeadCell>
              <Table.HeadCell>
                Org id
              </Table.HeadCell>
              <Table.HeadCell />
            </Table.Head>
            <Table.Body className="divide-y">
              { organizations.organizations.map((org) => (
                <Table.Row key={org.id} className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
                  <Table.Cell>
                    {org.id}
                  </Table.Cell>
                  <Table.Cell>
                    {org.name}
                  </Table.Cell>
                  <Table.Cell>
                    {org.orgId}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={OrganizationUsersRoute(org.id)}
                    >
                      <FontAwesomeIcon
                        className="self-center inline-right"
                        size="2xl"
                        icon={icon({ name: 'users', family: 'classic', style: 'regular' })}
                      />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )) }
            </Table.Body>
          </Table>
        </>
      ) : (
        <p className="py-2">No organizations found</p>
      )}

      <form className="flex flex-col gap-4 py-4" onSubmit={onSubmit}>
        {organizationCreateError && (
          <Alert
            color="failure"
          >
            <span>
              Organization creation failed, contact development reason:
              {' '}
              {organizationCreateError.message}
            </span>
          </Alert>
        )}

        {(createOrganizationResponse) && (
          <Alert
            color="success"
          >
            <div className="flex gap-x-1">
              Organization
              {' '}
              <div className="font-bold">{createOrganizationResponse.organizations.createOrganization.name}</div>
              {' '}
              with orgId
              <div className="font-bold">{createOrganizationResponse.organizations.createOrganization.orgId}</div>
              {' '}
              created
            </div>
          </Alert>
        )}

        <div>
          <Label
            htmlFor="name"
            value="Full organization name"
          />
          <TextInput
            type="text"
            placeholder="Good City Sense B.V."
            {...register('name', { required: true })}
          />
        </div>

        {errors.orgId !== undefined && (
          <Alert
            color="failure"
          >
            <span>
              Please use a different org id, it already exists
            </span>
          </Alert>
        )}

        <div>
          <Label
            htmlFor="orgId"
            value="Organization slug id"
          />
          <TextInput
            type="text"
            placeholder="goodcitysense"
            {...register('orgId', { required: true, validate: organizationDoesNotExist })}
          />
        </div>

        {createOrganizationLoading ? (
          <Button type="submit" disabled>
            Create
          </Button>
        ) : (
          <Button type="submit">
            Create
          </Button>
        )}
      </form>
    </div>
  );
}

export default OrganizationManagementApp;
