import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Asset, useGetPientereTuinenAssetsForUserQuery } from '../../generated/gql/types';
import { getProperty, PientereTuinenAssetProperty } from '../../AssetHelpers';

function usePientereTuinenAssetBySerial({ serial }: PientereTuinenAssetBySerialProps): AssetResponse {
  const { loading, error, data } = useGetPientereTuinenAssetsForUserQuery({
    context: { clientName: 'asset' },
    fetchPolicy: 'network-only',
  });

  const [assets, setAssets] = useState<Asset[]>([]);

  useEffect(() => {
    if (!error && data && data.pientereTuinenAssetsForUser.data != null
      && data.pientereTuinenAssetsForUser.data.length > 0) {
      setAssets(
        data.pientereTuinenAssetsForUser.data as Asset[],
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      Sentry.captureMessage(error.message, 'error');
    }
  }, [error]);

  return {
    asset: assets.find((a) => getProperty(a, PientereTuinenAssetProperty.SensorId)?.value === serial),
    loading,
    error: (error ? error?.message : undefined),
  };
}

type AssetResponse = {
  loading: boolean,
  asset: Asset | undefined,
  error: string | undefined
};

type PientereTuinenAssetBySerialProps = {
  serial: string
};

export default usePientereTuinenAssetBySerial;
