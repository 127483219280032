import React from 'react';
import { ReactComponent as GCSLogo } from '../images/good_city_sense_logo.svg';

function Footer() {
  return (
    <footer className="p-4 mt-4 bg-white wc-dark-blue sm:p-6">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
          <a href="https://www.goodcitysense.nl" className="flex items-center">
            <GCSLogo />
          </a>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>

            <ul className="text-wc-dark-blue">
              <li className="mb-4">
                <a href="https://www.goodcitysense.nl" className="hover:underline">Over ons</a>
              </li>
            </ul>
          </div>
          <div>

            <ul className="text-wc-dark-blue">
              <li className="mb-4">
                <a href="https://www.goodcitysense.nl/algemene-voorwaarden" className="hover:underline ">Voorwaarden</a>
              </li>
            </ul>
          </div>
          <div>

            <ul className="text-wc-dark-blue">
              <li className="mb-4">
                <a href="https://www.goodcitysense.nl/privacy" className="hover:underline">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-wc-dark-blue sm:text-center">
          ©
          {' '}
          {(new Date().getFullYear())}
          {' '}
          <a href="https://www.goodcitysense.nl" className="hover:underline pl-1">Good city sense</a>
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a href="https://www.linkedin.com/company/good-city-sense/" className="text-wc-dark-blue hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-5 h-5">
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              />
            </svg>
            <span className="sr-only">LinkedIn</span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
