/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LatLng } from 'leaflet';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import * as Sentry from '@sentry/react';
import PientereTuinenFooter from './PientereTuinenFooter';
import headerImg from '../../../images/pientere_tuinen_kruiwagen.png';
import sensorCode from '../../../images/sensor_registration.png';
import Alert from '../../Alert';
import {
  RegisterSensoterraSensorCommand,
  useCreatePientereTuinenAssetMutation, useDeleteMySensorRegistrationTokenMutation, useMySensorRegistrationTokenQuery,
  useRegisterSensoterraSensorMutation,
} from '../../../generated/gql/types';
import { DashboardRoute } from '../../Routes';
import useMyRoles from '../../hooks/useMyRoles';
import { defaultSoilType } from '../../hooks/usePientereTuinenSoilTypes';
import MapCoordinatesPicker, { latLngToString } from './MapCoordinatesPicker';
import Loader from '../../Loader';
import { GoodCitySenseOrganizationIdHeader } from '../../../Global';

function RegisterApp() {
  const myRoles = useMyRoles();
  const { idTokenPayload } = useOidcIdToken();
  const navigate = useNavigate();

  const { data: registrationTokenData, loading: registrationTokenLoading } = useMySensorRegistrationTokenQuery({
    fetchPolicy: 'network-only',
  });
  const [deleteMySensorRegistrationToken] = useDeleteMySensorRegistrationTokenMutation();
  const [registerSensor, { data, loading }] = useRegisterSensoterraSensorMutation();
  const [showModal, setShowModal] = useState({ show: false, redirect: false, msg: '' });
  const { register, setValue, handleSubmit } = useForm<RegisterSensoterraSensorForm>();

  const [createAsset, { data: assetData }] = useCreatePientereTuinenAssetMutation();

  const defaultCoordinates = new LatLng(Number('52.000000'), Number('5.000000'));

  function prefixSensorSerial(input: string): string {
    return `18000${input}`;
  }

  const onSubmit = handleSubmit((form) => {
    if (form.location === latLngToString(defaultCoordinates)) {
      setShowModal({ show: true, redirect: false, msg: 'Geef op de kaart aan bij stap 1b op welke plek in de tuin de sensor staat.' });
      return;
    }

    if (!registrationTokenData?.mySensorRegistrationToken?.organization?.orgId) {
      setShowModal({ show: true, redirect: false, msg: 'Er ging iets mis, ververs de pagina en probeer het opnieuw.' });
      return;
    }

    const scope = new Sentry.Scope();
    scope.setLevel('error');
    scope.setTag('registration_token_org_id', registrationTokenData?.mySensorRegistrationToken?.organization?.orgId);
    scope.setTag('serial_id', prefixSensorSerial(form.command.serial));
    scope.setTag('user_id', idTokenPayload?.gcs['user-id']);

    registerSensor({
      context: {
        headers: {
          [GoodCitySenseOrganizationIdHeader]: registrationTokenData?.mySensorRegistrationToken?.organization?.orgId,
        },
      },
      variables: {
        command: {
          serial: prefixSensorSerial(form.command.serial),
          soilTypeId: defaultSoilType.id,
        },
      },
    }).then((registerResponse) => {
      scope.setTag('internal_sensor_id', registerResponse.data?.sensors.registerSensoterraSensor.id);
      createAsset({
        context: {
          clientName: 'asset',
          headers: {
            [GoodCitySenseOrganizationIdHeader]: registrationTokenData?.mySensorRegistrationToken?.organization?.orgId,
          },
        },
        refetchQueries: [
          'GetPientereTuinenAssetsForUser',
        ],
        variables: {
          command: {
            id: registerResponse.data?.sensors.registerSensoterraSensor.id,
            name: 'PT Sensor',
            properties: [
              {
                key: 'user',
                value: idTokenPayload?.gcs['user-id'],
                type: 'user_id',
              },
              {
                key: 'sensor_id',
                value: prefixSensorSerial(form.command.serial),
                type: 'sensor_serial_id',
              },
              {
                key: 'organization_id',
                value: registrationTokenData?.mySensorRegistrationToken?.organization?.orgId,
                type: 'organization_id',
              },
              {
                key: 'soil',
                value: defaultSoilType.key,
                type: 'soil_type',
              },
              {
                key: 'location',
                value: form.location,
                type: 'coordinates',
              },
            ],
          },
        },
      }).then((_) => {
        deleteMySensorRegistrationToken().then((__) => {
          setShowModal({ show: true, redirect: true, msg: 'Je account is geactiveerd. Je gaat nu terug naar het Dashboard.' });
        }).catch((e) => {
          Sentry.captureMessage(e.toString(), scope);
        });
      }).catch((e) => {
        Sentry.captureMessage(e.toString(), scope);
        setShowModal({ show: true, redirect: false, msg: `Er is iets foutgegaan, neem contact op met good city sense support. Referentie id [${registerResponse.data?.sensors.registerSensoterraSensor.id}]` });
      });
    }).catch((e) => {
      Sentry.captureMessage(e.toString(), scope);
      setShowModal({ show: true, redirect: false, msg: 'Er is iets foutgegaan, probeer het opnieuw.' });
    });
  }, (_) => setShowModal({ show: true, redirect: false, msg: 'Vul de laatste 6 cijfers van de sensorcode in bij stap 1a.' }));

  function renderRegistrationForm() {
    return (
      <>
        <div>
          <h2 className="mb-5 text-4xl tracking-tight font-extrabold text-gray-900 ">Mijn Pientere Tuin</h2>
          <img className="mb-5" alt="Pientere Tuinen beeld" src={headerImg} />
          <h3 className="mb-4 text-3xl">Bodemsensor registreren</h3>

          <div className="mb-5">
            <p className="mb-4">
              Voordat je jouw bodemsensor kunt gebruiken, moet je die registeren. Als je
              jouw sensor hebt ontvangen, kun je dat hieronder doen. In de doos met de sensor
              vind je een handleiding met instructies.
            </p>
            <p className="mb-4">
              Na registratie zet je de bodemsensor in je tuin en volg je de verdere instructies
              in de handleiding. Na enige tijd begint de sensor dan de temperatuur en vochtigheid
              te meten op de plek waar je de sensor hebt geplaatst. Je kunt die informatie
              op deze website bekijken.
            </p>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-5">
            <div>
              <h4 className="mb-4 text-2xl">
                <span className="step">1a</span>
                &nbsp;Voer de sensorcode in
              </h4>
              <p className="mb-4">
                Op de sensor staat een sensorcode. Typ alleen de laatste 6 cijfers van die code in het
                veld hieronder. Een voorbeeld van de 6 cijfers is met een rode lijn aangegeven
                in de afbeelding.
              </p>
              <div className="flex">
                <input
                  type="text"
                  className="w-full flex-grow md:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mb-5"
                  placeholder="123456 (alleen cijfers)"
                  {...register('command.serial', {
                    minLength: 6,
                    maxLength: 6,
                    pattern: /^[0-9]*$/,
                    required: true,
                  })}
                />
              </div>
            </div>
            <p className="mb-5 justify-center">
              <img
                src={sensorCode}
                alt="sensorcode"
                className="mb-5 w-[400px] justify-right"
              />
            </p>
          </div>

          <h4 className="mb-4 text-2xl">
            <span className="step">1b</span>
            &nbsp;Geef aan waar de sensor staat
          </h4>
          <p className="mb-4">
            Zoom in op de kaart en zoek
            de plek waar ongeveer de sensor staat.
            Klik op die plek en het blauwe icoontje verschijnt daar.
          </p>

          <MapCoordinatesPicker
            register={register('location', { required: true })}
            setValue={setValue}
            location={defaultCoordinates}
            zoomLevel={9}
            withInputFields={false}
          />

          <h4 className="mb-4 text-2xl">
            <span className="step">1c</span>
            &nbsp;Activeer je account
          </h4>
          <p className="mb-4">
            Als je de goede zescijferige sensorcode hebt ingevuld en de locatie van de sensor hebt aangegeven,
            kun je de bodemsensor registeren door hieronder op de knop “Registreren” te klikken.
          </p>

          <div className="flex justify-center">
            {(loading || data || assetData || !idTokenPayload?.gcs || !myRoles) ? (
              <button
                type="submit"
                disabled
                className="w-64 border bg-wc-lightgray border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 mb-5 text-center font-medium focus:z-10 rounded-lg"
              >
                <span className="flex items-center rounded-md text-sm px-4 py-2">
                  Registreren
                  <svg
                    className="ml-2 -mr-1 h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            ) : (
              <button
                type="submit"
                className="btn-card w-64 border group flex h-min items-center justify-center p-0.5 mb-5 text-center font-bold focus:z-10 rounded-lg"
              >
                <span className="flex items-center rounded-md text-sm px-4 py-2">
                  Registreren
                  <svg
                    className="ml-2 -mr-1 h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            )}
          </div>
        </form>
      </>
    );
  }

  return (
    <div className="mx-2.5 md:mx-0">
      <section className="p-4 align-top w-full xl:w-1/2">
        <div className="rounded-lg bg-white shadow-md mb-8 p-5">
          {registrationTokenLoading ? (
            <Loader />
          ) : (
            <div>
              {registrationTokenData?.mySensorRegistrationToken ? (
                renderRegistrationForm()
              ) : (
                <>
                  <h2 className="mb-5 text-4xl tracking-tight font-extrabold text-gray-900 ">Mijn Pientere Tuin</h2>
                  <img className="mb-5" alt="Pientere Tuinen beeld" src={headerImg} />
                  <h3 className="mb-4 text-3xl">Bodemsensor al geregistreerd</h3>

                  <div className="mb-5">
                    <p className="mb-4">
                      Het lijkt erop dat je je sensor al geregistreerd hebt. Ga terug naar het dashboard om je sensor waardes te bekijken
                      {' '}
                      <Link className="underline text-wc-blue" to={DashboardRoute()}>dashboard</Link>
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </section>

      {showModal.redirect ? (
        <Alert
          show={showModal.show}
          msg={showModal.msg}
          onHide={() => navigate(DashboardRoute())}
        />
      ) : (
        <Alert
          show={showModal.show}
          msg={showModal.msg}
          onHide={() => setShowModal({ show: false, redirect: false, msg: '' })}
        />
      )}
      <PientereTuinenFooter />
    </div>
  );
}

type RegisterSensoterraSensorForm = {
  command: RegisterSensoterraSensorCommand
  location: string
};

export default RegisterApp;
