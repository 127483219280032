import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Label, Select, Table, TextInput,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import {
  useGetZohoOrganizationsQuery,
  useSendVerificationEmailMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function SentVerificationEmailApp() {
  const { register, handleSubmit } = useForm<SendVerificationEmailForm>();
  const [sendVerificationEmail, { data, loading, error }] = useSendVerificationEmailMutation();
  const { t } = useTranslation();
  const {
    data: zohoOrganizations,
    loading: zohoOrganizationsLoading,
    error: zohoOrganizationsError,
  } = useGetZohoOrganizationsQuery({ fetchPolicy: 'network-only' });

  const onSubmit = handleSubmit((form) => {
    if (form.pientereTuinenOrgId === '0') {
      return;
    }

    sendVerificationEmail({
      variables: {
        pientereTuinenOrgId: form.pientereTuinenOrgId,
      },
    });
  });

  if (zohoOrganizationsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full md:w-1/2 text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          {t('zoho management')}
        </h2>

        <h3 className="mb-4 text-3xl w-full md:w-1/2">Send verification emails</h3>
        <p>
          {t('sent verification emaildescription')}
        </p>
      </div>

      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <div>
          {zohoOrganizations && zohoOrganizations?.zoho?.organizations?.length > 0 && !zohoOrganizationsError ? (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="pientereTuinenOrgId"
                  value="Select a zoho organization"
                />
              </div>
              <Select
                required
                {...register('pientereTuinenOrgId', {
                  value: '0',
                  required: true,
                })}
              >
                <option value="0" disabled>Select a zoho organization</option>
                {zohoOrganizations?.zoho?.organizations?.map(
                  (o) => (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ),
                )}
              </Select>
            </>
          ) : (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="pientereTuinenOrgId"
                  value="No zoho organizations found, please provide a zoho organization"
                />
              </div>
              <TextInput
                type="text"
                placeholder="12345"
                required
                {...register('pientereTuinenOrgId')}
              />
            </>
          )}
        </div>
        {loading ? (
          <Button type="submit" disabled>
            Import
          </Button>
        ) : (
          <Button type="submit">
            Import
          </Button>
        )}

      </form>

      {loading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          {(error && (
            <div className="py-4">
              <p className="font-bold">Oops something went wrong: </p>
              <p>{error.message}</p>
            </div>
          ))}

          {((data && data.zoho.sendVerificationEmails.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">
                Sent emails (
                {data.zoho.sendVerificationEmails.length}
                )
              </h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    Message
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {data.zoho.sendVerificationEmails.map((message: string) => (
                    <Table.Row key={message} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {message}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          ))}

          {(data && data.zoho.sendVerificationEmails.length <= 0 && (
            <p className="py-4">No emails have been send</p>
          ))}
        </div>
      )}

    </div>
  );
}

type SendVerificationEmailForm = {
  pientereTuinenOrgId: string
};

export default SentVerificationEmailApp;
