import React from 'react';
import { Navbar } from 'flowbite-react';
import { Link } from 'react-router-dom';
import Profile from './Profile';
import { DashboardRoute } from './Routes';
import { ReactComponent as GCSLogo } from '../images/good_city_sense_logo.svg';

function Navigation({ hasProfile } : NavigationProps) {
  return (
    <Navbar
      fluid
      rounded
      className="mt-2 pb-2 mb-2 border-b border-slate-500"
    >
      <Link to={DashboardRoute()}>
        <div className="h-18">
          <GCSLogo />
        </div>
      </Link>

      {hasProfile ? (
        <div className="flex md:order-2 flex-wrap gap-2">
          <Profile />
        </div>
      ) : ''}

    </Navbar>
  );
}

type NavigationProps = {
  hasProfile: React.ReactNode
};

export default Navigation;
