import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import openapi from './openapi.json';
import openapiAcc from './openapi-acc.json';
import 'swagger-ui-react/swagger-ui.css';

function PientereTuinenSubscriptionApp() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return <SwaggerUI spec={openapi} />;
  }
  return <SwaggerUI spec={openapiAcc} />;
}

export default PientereTuinenSubscriptionApp;
