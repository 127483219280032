import React from 'react';
import { Navbar } from 'flowbite-react';
import { LandingRoute } from './Routes';
import { ReactComponent as GCSLogo } from '../images/good_city_sense_logo.svg';

function SessionTimeoutPage() {
  return (
    <div className="container mx-auto">
      <Navbar
        fluid
        rounded
        className="mt-2 pb-2 mb-5 border-b border-slate-500"
      >
        <Navbar.Brand>
          <div className="h-18">
            <GCSLogo />
          </div>
        </Navbar.Brand>

      </Navbar>
      <div role="status">
        <p>Uw sessie is verlopen</p>
        <p>
          Log aub opnieuw in
          <a
            href={LandingRoute()}
            className="btn-card bg-wc-lightgray group flex h-min items-center justify-center p-0.5 px-2 m-5 text-center font-bold focus:z-10 rounded-lg"
          >
            <span className="text-center rounded-md text-sm px-4 py-2">Inloggen</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default SessionTimeoutPage;
