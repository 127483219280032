/* eslint-disable react/style-prop-object */
import React from 'react';
import {
  FieldErrors, UseFormRegister, UseFormSetValue, UseFormUnregister,
} from 'react-hook-form';
import { Tooltip } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import Select from 'react-select';
import {
  HeightInMeterClass, InputSpecies, Tree, useGetTreeSpeciesQuery,
} from '../../../generated/gql/types';
import Dropdown, { DropdownOption } from './Dropdown';
import Loader from '../../Loader';

function TreeForm({
  tree, register, unregister, setValue, errors,
}: TreeFormProps) {
  const { t } = useTranslation();
  const { data: treeSpecies, loading: loadingTreeSpecies } = useGetTreeSpeciesQuery();

  function setSpecies(e: SingleValue<{ label: string | null | undefined, value: string | null | undefined }>) {
    if (treeSpecies?.trees.treeSpecies?.find((species) => species.code === e?.value)) {
      setValue('inputSpecies.speciesCode', e?.value);
      unregister('inputSpecies.speciesFreeText');
    } else {
      unregister('inputSpecies.speciesCode');
      setValue('inputSpecies.speciesFreeText', e?.value);
    }
  }

  if (loadingTreeSpecies) {
    return (
      <div className="flex items-center mx-auto h-full">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <input type="hidden" {...register('id', { value: tree?.id ?? undefined })} />

      <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
        {t('tree species')}
        <Tooltip
          content={(
            <>
              <div>
                kijk eerst of jouw boom voorkomt in de lijst. Van deze bomen zijn de groene baten al bekend.
              </div>
              <div>
                Bij het toevoegen van een nieuwe boom moeten de baten nog berekend worden,
              </div>
              <div>
                het kan enige tijd duren voor je dat terugziet op de kaart.
              </div>
            </>
)}
          style="light"
          className="!z-[9999]"
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
        </Tooltip>

        {errors && (
          <div className="text-red-600">{errors.inputSpecies?.message}</div>
        )}
      </div>

      <Select
        required
        isSearchable
        placeholder="Selecteer een boomsoort"
        isLoading={loadingTreeSpecies}
        defaultValue={{
          label: tree?.species?.name ? `${tree.species?.name} (${tree.species?.scientificName})` : tree?.species?.scientificName,
          value: tree?.species?.code,
        }}
        options={
            treeSpecies?.trees.treeSpecies.map((species) => (
              {
                label: species.name ? `${species.name} (${species.scientificName})` : species.scientificName,
                value: species.code,
              }
            )).sort((a, b) => ((a.label ?? '') > (b.label ?? '') ? 1 : -1))
          }
        className="w-full mt-2 mb-4"
        classNamePrefix="select"
        onChange={(e) => setSpecies(e)}
      />
      <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
        {t('tree height')}
        <Tooltip
          content={(
            <>
              <div>Schat de afstand tot het hoogste punt van de boom.</div>
              <div>Kijk bijvoorbeeld naar gebouwen in de omgeving om de hoogte in te schatten</div>
            </>
            )}
          style="light"
          className="!z-[9999]"
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
        </Tooltip>
      </div>
      <Dropdown
        register={register('heightClass', {
          required: true,
          pattern: /^((?!undefined).)*$/,
          value: tree?.sizes.heightClass ?? HeightInMeterClass.ZeroToThree,
        })}
        options={Object.values(HeightInMeterClass).map((height) => (
          { name: t(height), value: height.toString() } as DropdownOption
        ))}
      />
      <div className="mt-2 font-bold text-gray-900 flex flex-row gap-x-1">
        {t('tree crown diameter')}
        <Tooltip
          content={(
            <>
              <div>De boomkruin is het bovenste gedeelte van een boom. Het zijn de takken met daaraan de bladeren. </div>
              <div>Bij de diameter gaat het om de afstand tussen het breedste gedeelte van de boom.</div>
            </>
            )}
          style="light"
          className="!z-[9999]"
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
        </Tooltip>
      </div>
      <Dropdown
        register={register('crownDiameterClass', {
          required: true,
          pattern: /^((?!undefined).)*$/,
          value: tree?.sizes.crownDiameterClass ?? HeightInMeterClass.ZeroToThree,
        })}
        options={Object.values(HeightInMeterClass).map((height) => (
          { name: t(height), value: height.toString() } as DropdownOption
        ))}
      />
    </>
  );
}

type TreeFormProps = {
  tree: Tree | undefined
  register: UseFormRegister<TreeFormData>
  unregister: UseFormUnregister<TreeFormData>
  errors: FieldErrors<TreeFormData>
  setValue: UseFormSetValue<TreeFormData>
};

export type TreeFormData = {
  id: string | undefined
  inputSpecies: InputSpecies;
  heightClass: HeightInMeterClass;
  crownDiameterClass: HeightInMeterClass;
};

export default TreeForm;
