import React, { ReactNode } from 'react';
import { Modal } from 'flowbite-react';

function Alert({
  show, msg, onHide, size = 'md', buttonText = 'OK', closeButtonEnabled = true,
} : AlertProps) {
  return (
    <Modal
      show={show}
      size={size}
      popup
      onClose={onHide}
      className="!z-[9999]"
    >
      {closeButtonEnabled && (
        <Modal.Header />
      )}
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {msg}
          </h3>
          <div className="flex justify-center gap-4">
            <button
              type="button"
              color="gray"
              onClick={onHide}
              className="w-64 border bg-wc-lightgray border-wc-gray hover:text-white hover:bg-wc-gray group flex h-min items-center justify-center p-0.5 mb-5 text-center font-medium z-50 rounded-lg"
            >
              <span className="flex items-center rounded-md text-sm px-4 py-2">{buttonText}</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

type AlertProps = {
  show: boolean;
  msg: string | ReactNode;
  onHide: any;
  size?: string
  buttonText?: string
  closeButtonEnabled?: boolean
};

export default Alert;
