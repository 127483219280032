import React, { CSSProperties, useEffect, useState } from 'react';
import L, {
  LatLng, LatLngBounds, LeafletMouseEvent,
} from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  LayerGroup,
  MapContainer, Marker, Popup, Rectangle, TileLayer, useMapEvent,
} from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'flowbite-react';
import { round } from 'lodash';
import { Tree, useSearchTreesQuery } from '../../../generated/gql/types';
import Loader from '../../Loader';
import TreeDetails from './TreeDetails';
import TreeEdit from './TreeEdit';
import TreeCreate from './TreeCreate';
import TreeIcon from './TreeIcon';

type SetCoordinatesOnClickProps = {
  clickHandler: (p: LatLng) => void
};

function SetCoordinatesOnClick({ clickHandler }: SetCoordinatesOnClickProps) {
  useMapEvent('click', (e: LeafletMouseEvent) => {
    clickHandler(e.latlng);
  });

  return null;
}

function TreeMap({ assetCoordinates }: TreeMapProps) {
  const treeAreaRadius = 25;
  const bounds = assetCoordinates?.toBounds(treeAreaRadius) ?? new LatLng(0, 0).toBounds(treeAreaRadius);
  const [activeTree, setActiveTree] = useState<Tree | undefined>(undefined);
  const [editTree, setEditTree] = useState<boolean>(false);
  const [newTree, setNewTree] = useState<boolean>(false);
  const [
    newTreeCoordinates,
    setNewTreeCoordinates,
  ] = useState<LatLng | undefined>(undefined);

  const { data, loading } = useSearchTreesQuery({
    fetchPolicy: 'network-only',
    variables: {
      query: {
        minLongitude: bounds.getNorthWest().lng,
        minLatitude: bounds.getNorthWest().lat,
        maxLongitude: bounds.getSouthEast().lng,
        maxLatitude: bounds.getSouthEast().lat,
        limit: 100,
      },
    },
  });

  useEffect(() => {
    setNewTreeCoordinates(undefined);
  }, [newTree, editTree]);

  const validateAndSetNewTreeCoordinates = (coordinates: LatLng) => {
    if (coordinates && bounds.contains(coordinates)) {
      setNewTreeCoordinates(coordinates);
    }
  };

  function treeHasBenefits(tree: Tree): boolean {
    if (tree.benefits !== undefined && tree.benefits !== null) {
      return tree.benefits.yearlyCapturedCo2InKg !== undefined && tree.benefits.yearlyCapturedPollutionInG !== undefined;
    }

    return false;
  }

  function closeDetails() {
    setEditTree(false);
    setActiveTree(undefined);
  }

  const allGreenStyle = {
    '--fa-primary-color': 'green',
    '--fa-secondary-color': 'green',
    '--fa-primary-opacity': 0.7,
    '--fa-secondary-opacity': 0.5,
  } as CSSProperties;

  const greenStyle = {
    '--fa-primary-color': 'brown',
    '--fa-secondary-color': 'green',
    '--fa-primary-opacity': 0.7,
    '--fa-secondary-opacity': 0.5,
  } as CSSProperties;

  const whiteStyle = {
    '--fa-primary-color': 'brown',
    '--fa-secondary-color': 'white',
    '--fa-primary-opacity': 0.5,
    '--fa-secondary-opacity': 0.5,
  } as CSSProperties;

  const greyStyle = {
    '--fa-primary-color': 'brown',
    '--fa-secondary-color': 'grey',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;

  function renderTree(tree: Tree) {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <div className="relative">
          <div className="sensor-type-icon text-wc-blue">
            {tree.id === activeTree?.id ? (
              <TreeIcon tree={tree} style={greyStyle} />
            ) : (
              <TreeIcon tree={tree} style={treeHasBenefits(tree) ? greenStyle : whiteStyle} />
            )}
          </div>
        </div>,
      ),
      className: '',
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    });
  }

  function renderNewTree() {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <div className="relative">
          <div className="sensor-type-icon text-wc-blue">
            <FontAwesomeIcon
              icon={icon({ name: 'seedling', family: 'classic', style: 'duotone' })}
              size="4x"
              style={allGreenStyle}
            />
          </div>
        </div>,
      ),
      className: '',
      iconSize: [48, 48],
      iconAnchor: [24, 48],
    });
  }

  function getTreeBenefitsTotal() {
    let totalYearlyCapturedCo2InKg = 0;
    let totalYearlyCapturedWaterInM3 = 0;
    let totalYearlyCapturedPollutionInG = 0;
    let totalWorthInEuro = 0;

    data?.trees.treeSearch?.forEach((tree) => {
      if (treeHasBenefits(tree)) {
        totalYearlyCapturedCo2InKg += tree?.benefits?.yearlyCapturedCo2InKg ?? 0;
        totalYearlyCapturedWaterInM3 += tree?.benefits?.yearlyCapturedWaterInM3 ?? 0;
        totalYearlyCapturedPollutionInG += tree?.benefits?.yearlyCapturedPollutionInG ?? 0;
        totalWorthInEuro += tree?.benefits?.worthInEuro ?? 0;
      }
    });

    return {
      totalYearlyCapturedCo2InKg,
      totalYearlyCapturedWaterInM3,
      totalYearlyCapturedPollutionInG,
      totalWorthInEuro,
    };
  }

  function renderTreePanel() {
    if (activeTree) {
      return (
        <div className="flex flex-col">
          <div className="flex justify-end">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={icon({ name: 'xmark' })}
              onClick={() => closeDetails()}
            />
          </div>
          {editTree ? (
            <TreeEdit tree={activeTree} newCoordinates={newTreeCoordinates} closeHandler={() => setEditTree(false)} />
          ) : (
            <TreeDetails
              tree={activeTree}
              closeDeletedHandler={() => {
                setEditTree(false);
                setActiveTree(undefined);
              }}
              editHandler={() => setEditTree(!editTree)}
            />
          )}
        </div>
      );
    }
    return (
      <>
        <div className="text-xl">Bomen in jouw omgeving</div>
        <div className="flex-col items-center">
          <div className=" my-1 mb-2 text-sm ">
            Op de kaart zie je de bomen rondom de locatie van jouw sensor. Elke boom levert
            een positieve bijdrage CO
            <sub>2</sub>
            {' '}
            op te nemen, water vast te houden of fijnstof uit de lucht te filteren.
            Hieronder zie je de groene baten van alle bekende bomen binnen een straal van 25 meter
            rondom jouw Pientere Tuin sensor. Die baten kunnen alleen berekend worden
            als bepaalde kenmerken van een boom bekend zijn. Bij de volledig groene bomen
            op de kaart is dat al berekend. Bij de andere bomen zijn niet alle kenmerken bekend.
            Jij kunt ons helpen om die gegevens aan te vullen. Klik op een boom op de kaart
            voor meer informatie over die boom.
          </div>
          <div className="my-1 mb-2 text-sm">
            In de
            {' '}
            <a
              href="https://help.goodcitysense.nl/portal/nl/kb/articles/bomen-in-de-buurt"
              target="_blank"
              rel="noreferrer"
              className="text-wc-blue underline"
            >
              kennisbank
            </a>
            {' '}
            staat een uitgebreid artikel waarin uitgelegd wordt hoe de groene baten worden
            berekend en hoe je kunt helpen om de informatie aan te vullen.
          </div>
        </div>

        <div>
          <div className="bg-[#e4f1db] flex gap-y-2 flex-col p-2 text-xs xl:text-base">
            <div className="font-bold">De groene baten van de bomen in jouw omgeving</div>
            <table className="border-separate border-spacing-2">
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      size="2xl"
                      className="text-wc-blue"
                      icon={icon({ name: 'trees', family: 'classic', style: 'light' })}
                    />
                  </td>
                  <td>
                    <div className="gap-x-1 flex items-center">
                      <div className="whitespace-normal">
                        Aantal bomen binnen
                        {' '}
                        {treeAreaRadius}
                        {' '}
                        meter van jouw sensor:
                        {' '}
                        <div className="font-bold inline-block">{data?.trees.treeSearch?.length ?? 0}</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      size="2xl"
                      className="text-wc-blue"
                      icon={icon({ name: 'seedling', family: 'classic', style: 'light' })}
                    />
                  </td>
                  <td>
                    <div className="flex items-center">
                      <div className="whitespace-normal">
                        Hoeveelheid
                        CO
                        <sub className="mr-1 inline-block">2</sub>
                        die
                        <div className="font-bold mx-1 inline-block whitespace-nowrap">
                          {data?.trees.treeSearch?.length ?? 0}
                          {' '}
                          bomen
                        </div>
                        per jaar opslaan:
                        <div
                          className="font-bold ml-1 inline-block"
                        >
                          {round(getTreeBenefitsTotal().totalYearlyCapturedCo2InKg)}
                          {' '}
                          kilo
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      size="2xl"
                      className="text-wc-blue"
                      icon={icon({
                        name: 'hand-holding-droplet',
                        family: 'classic',
                        style: 'light',
                      })}
                    />
                  </td>
                  <td>
                    <div className="gap-x-1 flex flex-wrap items-center">
                      <div className="whitespace-normal">
                        Hoeveelheid water die
                        {' '}
                        <div className="font-bold inline-block">
                          {data?.trees.treeSearch?.length ?? 0}
                          {' '}
                          bomen
                          {' '}
                        </div>
                        {' '}
                        per jaar afvangen:
                        {' '}
                        <div
                          className="font-bold inline-block"
                        >
                          {round(getTreeBenefitsTotal().totalYearlyCapturedWaterInM3 * 1000)}
                          {' '}
                          liter
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      size="2xl"
                      className="text-wc-blue"
                      icon={icon({ name: 'leaf-heart', family: 'classic', style: 'light' })}
                    />
                  </td>
                  <td>
                    <div className="gap-x-1 flex flex-wrap items-center">
                      Hoeveelheid luchtvervuiling die
                      {' '}
                      <div className="font-bold">
                        {data?.trees.treeSearch?.length ?? 0}
                        {' '}
                        bomen
                        {' '}
                      </div>
                      per jaar filteren:
                      <div
                        className="font-bold"
                      >
                        {round(getTreeBenefitsTotal().totalYearlyCapturedPollutionInG)}
                        {' '}
                        gram
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      size="2xl"
                      className="text-wc-blue"
                      icon={icon({ name: 'coins', family: 'classic', style: 'light' })}
                    />
                  </td>
                  <td>
                    <div className="gap-x-1 flex flex-wrap items-center">
                      <div className="whitespace-normal">
                        De milieuwaarde van
                        {' '}
                        <div className="font-bold inline-block">
                          {data?.trees.treeSearch?.length ?? 0}
                          {' '}
                          bomen
                          {' '}
                        </div>
                        :
                        {' '}
                        <div className="font-bold inline-block">
                          {getTreeBenefitsTotal().totalWorthInEuro}
                          {' '}
                          euro
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

          <Button type="button" className="btn self-start hover:bg-wc-hover-blue mt-4" onClick={() => setNewTree(true)}>
            Nieuwe boom toevoegen
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
      <div className="w-full inline-block relative align-top rounded-lg border border-gray-400 bg-white shadow-md">
        {loading && (
          <div className="absolute right-0 top-0 !z-[9999]">
            <div className="p-4">
              <Loader />
            </div>
          </div>
        )}
        <MapContainer
          className="h-[300px] lg:h-[600px]"
          center={assetCoordinates}
          zoom={20}
          minZoom={18}
          maxZoom={22}
          zoomSnap={0.5}
          zoomDelta={0.5}
          zoomControl
          dragging={false}
          scrollWheelZoom="center"
          maxBounds={new LatLngBounds(
            new LatLng(50.6309883, 3.0625035),
            new LatLng(53.7181849, 7.2928109),
          )}
        >
          <LayerGroup>
            <Rectangle bounds={bounds} pathOptions={{ color: 'black', fill: false, dashArray: '8' }} />
          </LayerGroup>

          {data?.trees.treeSearch.map((tree) => (
            <Marker
              key={`tree-${tree.id ?? ''}`}
              position={new LatLng(tree.latitude, tree.longitude)}
              icon={renderTree(tree)}
              eventHandlers={{
                click: () => {
                  if (!newTree) {
                    setEditTree(false);
                    setActiveTree(tree);
                  }
                },
              }}
            />
          ))}

          {newTreeCoordinates && (
            <Marker
              position={new LatLng(newTreeCoordinates?.lat, newTreeCoordinates?.lng)}
              icon={renderNewTree()}
            />
          )}

          <TileLayer
            attribution=""
            maxZoom={22}
            maxNativeZoom={19}
            url={`${process.env.REACT_APP_WMTS_URL}/topoplus_achtergrond/wm_19/{z}/{x}/{y}.jpeg`}
          />

          <Marker
            position={assetCoordinates ?? new LatLng(0, 0)}
          >
            <Popup minWidth={90}>
              <span>
                Hier staat jouw sensor
              </span>
            </Popup>
          </Marker>

          {newTree && (
            <SetCoordinatesOnClick clickHandler={validateAndSetNewTreeCoordinates} />
          )}

        </MapContainer>
      </div>
      <div className="w-full flex flex-col p-4 align-top rounded-lg border border-gray-400 bg-white shadow-md">
        {loading ? (
          <div className="flex items-center mx-auto h-full">
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              {newTree ? (
                <TreeCreate newCoordinates={newTreeCoordinates} closeHandler={() => setNewTree(false)} />
              ) : (
                <>{renderTreePanel()}</>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export type TreeMapProps = {
  assetCoordinates: LatLng
};

export default TreeMap;
