import React from 'react';
import {
  faTemperatureHalf, faDroplet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AssetDataLink({ assetId }: AssetDataLinkProps) {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return (
      <>
        <a
          className="cursor-pointer"
          href={`https://cubejs.internal.goodcitysense.nl/#/build?query={%22measures%22:[%22pientere_tuinen_measurements.avg_temperature_celsius%22],%22timeDimensions%22:[{%22dimension%22:%22pientere_tuinen_measurements.measured_at%22,%22granularity%22:%22hour%22,%22dateRange%22:%22Last%207%20days%22}],%22order%22:{%22pientere_tuinen_measurements.avg_temperature_celsius%22:%22desc%22},%22dimensions%22:[%22pientere_tuinen_measurements.sensor_id%22],%22filters%22:[{%22member%22:%22pientere_tuinen_measurements.sensor_id%22,%22operator%22:%22equals%22,%22values%22:[%22${assetId}%22]}]}`}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTemperatureHalf} className="cursor-pointer" />
        </a>
        <a
          className="cursor-pointer"
          href={`https://cubejs.internal.goodcitysense.nl/#/build?query={%22measures%22:[%22pientere_tuinen_measurements.avg_moisture_percentage%22],%22timeDimensions%22:[{%22dimension%22:%22pientere_tuinen_measurements.measured_at%22,%22granularity%22:%22hour%22,%22dateRange%22:%22Last%207%20days%22}],%22order%22:{%22pientere_tuinen_measurements.avg_moisture_percentage%22:%22desc%22},%22dimensions%22:[%22pientere_tuinen_measurements.sensor_id%22],%22filters%22:[{%22member%22:%22pientere_tuinen_measurements.sensor_id%22,%22operator%22:%22equals%22,%22values%22:[%22${assetId}%22]}]}`}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faDroplet} className="cursor-pointer" />
        </a>
      </>
    );
  }
  return (
    <>
      <a
        className="cursor-pointer"
        href={`https://cubejs.internal-acc.goodcitysense.nl/#/build?query={%22measures%22:[%22pientere_tuinen_measurements.avg_temperature_celsius%22],%22timeDimensions%22:[{%22dimension%22:%22pientere_tuinen_measurements.measured_at%22,%22granularity%22:%22hour%22,%22dateRange%22:%22Last%207%20days%22}],%22order%22:{%22pientere_tuinen_measurements.avg_temperature_celsius%22:%22desc%22},%22dimensions%22:[%22pientere_tuinen_measurements.sensor_id%22],%22filters%22:[{%22member%22:%22pientere_tuinen_measurements.sensor_id%22,%22operator%22:%22equals%22,%22values%22:[%22${assetId}%22]}]}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTemperatureHalf} className="cursor-pointer" />
      </a>
      <a
        className="cursor-pointer"
        href={`https://cubejs.internal-acc.goodcitysense.nl/#/build?query={%22measures%22:[%22pientere_tuinen_measurements.avg_moisture_percentage%22],%22timeDimensions%22:[{%22dimension%22:%22pientere_tuinen_measurements.measured_at%22,%22granularity%22:%22hour%22,%22dateRange%22:%22Last%207%20days%22}],%22order%22:{%22pientere_tuinen_measurements.avg_moisture_percentage%22:%22desc%22},%22dimensions%22:[%22pientere_tuinen_measurements.sensor_id%22],%22filters%22:[{%22member%22:%22pientere_tuinen_measurements.sensor_id%22,%22operator%22:%22equals%22,%22values%22:[%22${assetId}%22]}]}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faDroplet} className="cursor-pointer" />
      </a>
    </>
  );
}

type AssetDataLinkProps = {
  assetId: string
};

export default AssetDataLink;
